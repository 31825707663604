<template>
  <v-card
    tile
    elevation="5"
    class="notification-template-editor-sidebar fill-height"
  >
    <v-container>
      <template v-if="editorModalSelectedRecord.email">
        <v-row>
          <v-col cols="12" class="d-flex justify-space-between align-center">
            <span class="text-h5">{{
              $trans("notification_template_editor_sidebar_email_title")
            }}</span>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-divider />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-textarea
              ref="email-subject"
              v-model="emailSubject"
              outlined
              :rows="2"
              :label="$trans('email_subject')"
              :v-scroll="false"
              no-resize
              counter="65"
              :counter-value="counterValue"
            >
              <template #append-outer>
                <notification-variable-menu
                  :variables="getEmailSubjectVariables"
                  @select="
                    insertTextOnCursorPosition(
                      $event,
                      'email-subject',
                      'emailSubject',
                    )
                  "
                />
              </template>
            </v-textarea>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <tip-tap
              ref="email-text"
              v-model="emailText"
              :label="$trans('notification_template_editor_sidebar_text')"
              :placeholder="$trans('notification_template_editor_sidebar_text')"
              @input="emailText = $event"
            />
          </v-col>
          <v-col cols="12">
            <notification-variable-menu
              show-text
              :variables="getEmailVariables"
              @select="insertTextToHTMLTextarea($event, 'email-text')"
            />
          </v-col>
        </v-row>
      </template>
      <template v-if="editorModalSelectedRecord.sms">
        <v-row class="mt-4">
          <v-col cols="12" class="d-flex justify-space-between align-center">
            <span class="text-h5">{{
              $trans("notification_template_editor_sidebar_sms_title")
            }}</span>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-divider />
          </v-col>
        </v-row>
        <v-row>
          <v-col
            v-if="
              getNonGsmCharacters(smsText) &&
              getNonGsmCharacters(smsText).length > 0
            "
          >
            {{ $trans("notification_contains_special_characters") }}:
            {{ getNonGsmCharacters(smsText).join(", ") }}
          </v-col>
          <v-col cols="12" class="pb-0">
            <v-textarea
              ref="sms-text"
              v-model="smsText"
              outlined
              :rows="4"
              :label="$trans('notification_template_editor_sidebar_text')"
              no-resize
              :hint="getSMSHint(smsText)"
              counter="160"
              :counter-value="counterValue"
              persistent-hint
            />
          </v-col>
          <v-col class="pt-0">
            <notification-variable-menu
              show-text
              :variables="getAllVariables"
              @select="
                insertTextOnCursorPosition($event, 'sms-text', 'smsText')
              "
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <small>*{{ $trans("sms_number_of_messages_info") }}</small>
          </v-col>
        </v-row>
      </template>
      <template v-if="editorModalSelectedRecord.push">
        <v-row class="mt-4">
          <v-col cols="12" class="d-flex justify-space-between align-center">
            <span class="text-h5">{{
              $trans("notification_template_editor_sidebar_push_title")
            }}</span>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-divider />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-textarea
              ref="push-subject"
              v-model="pushSubject"
              outlined
              :rows="2"
              :label="$trans('email_subject')"
              no-resize
              counter="65"
              :counter-value="counterValue"
            >
              <template #append-outer>
                <notification-variable-menu
                  :variables="getAllVariables"
                  @select="
                    insertTextOnCursorPosition(
                      $event,
                      'push-subject',
                      'pushSubject',
                    )
                  "
                />
              </template>
            </v-textarea>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" class="pb-0">
            <v-textarea
              ref="push-text"
              v-model="pushText"
              outlined
              :rows="4"
              :label="$trans('notification_template_editor_sidebar_text')"
              no-resize
              counter="240"
              :counter-value="counterValue"
            />
          </v-col>
          <v-col cols="12" class="pt-0">
            <notification-variable-menu
              show-text
              :variables="getAllVariables"
              @select="
                insertTextOnCursorPosition($event, 'push-text', 'pushText')
              "
            />
          </v-col>
        </v-row>
      </template>
      <v-row>
        <v-col>
          <v-divider />
        </v-col>
      </v-row>
      <v-row>
        <v-col class="d-flex align-center justify-center">
          <v-btn color="danger" text small @click="clearAllNotifications">
            <span>
              {{ $trans("notification_template_editor_sidebar_restore_all") }}
            </span>
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
  </v-card>
</template>

<script>
import Vue from "vue";

import { mapActions, mapGetters } from "vuex";
import EditorContentController from "@/views/dashboard/pages/Notifications/NotificationTemplateEditor/EditorContentController";
import NotificationVariableMenu from "@/views/dashboard/pages/Notifications/NotificationTemplateEditor/components/NotificationVariableMenu.vue";
import { errorNotification } from "@/lib/calendesk-js-library/tools/notification";
import sharedConfirmDialogTypes from "@/lib/calendesk-js-library/components/confirmDialogs/sharedConfirmDialogTypes";
import { trans } from "@/lib/calendesk-js-library/prototypes/trans";
import {
  insertTextOnCursorPosition,
  insertTextToHTMLTextarea,
} from "@/lib/calendesk-js-library/tools/helpers";
import TipTap from "@/lib/calendesk-js-library/components/TipTap.vue";
import smsActions from "@/calendesk/mixins/smsActions";
import sharedActions from "@/lib/calendesk-js-library/mixins/sharedActions";

export default Vue.extend({
  name: "NotificationTemplateEditorSidebar",
  components: { TipTap, NotificationVariableMenu },
  mixins: [sharedActions, smsActions],
  data() {
    return {
      initialValuesSet: false,
      emailSubject: null,
      emailText: null,
      pushSubject: null,
      pushText: null,
      smsText: null,
    };
  },
  computed: {
    ...mapGetters({
      editorModalSelectedRecord: "notification/getEditorModalSelectedRecord",
      editorModalPreviewContent: "notification/getEditorModalPreviewContent",
    }),
    getEmailAccountVariables() {
      return [
        { text: trans("variable_customer_id"), value: "CUSTOMER_ID" },
        {
          text: trans("variable_customer_full_name"),
          value: "CUSTOMER_FULL_NAME",
        },
        { text: trans("variable_customer_name"), value: "CUSTOMER_NAME" },
        {
          text: trans("variable_customer_surname"),
          value: "CUSTOMER_SURNAME",
        },
        { text: trans("variable_customer_email"), value: "CUSTOMER_EMAIL" },
        { text: trans("variable_customer_phone"), value: "CUSTOMER_PHONE" },
        { text: trans("variable_company_name"), value: "COMPANY_NAME" },
        { text: trans("variable_company_phone"), value: "COMPANY_PHONE" },
        { text: trans("variable_company_address"), value: "COMPANY_ADDRESS" },
      ];
    },
    getEmailNewsletterVariables() {
      return [
        {
          text: trans("variable_newsletter_email"),
          value: "NEWSLETTER_EMAIL",
        },
        { text: trans("variable_company_name"), value: "COMPANY_NAME" },
        { text: trans("variable_company_phone"), value: "COMPANY_PHONE" },
        { text: trans("variable_company_address"), value: "COMPANY_ADDRESS" },
        { text: trans("variable_unsubscribe_url"), value: "UNSUBSCRIBE_URL" },
      ];
    },
    getEmailSimpleStoreVariables() {
      return [
        { text: trans("variable_customer_id"), value: "CUSTOMER_ID" },
        {
          text: trans("variable_customer_full_name"),
          value: "CUSTOMER_FULL_NAME",
        },
        { text: trans("variable_customer_name"), value: "CUSTOMER_NAME" },
        {
          text: trans("variable_customer_surname"),
          value: "CUSTOMER_SURNAME",
        },
        { text: trans("variable_customer_email"), value: "CUSTOMER_EMAIL" },
        { text: trans("variable_customer_phone"), value: "CUSTOMER_PHONE" },
        { text: trans("variable_company_name"), value: "COMPANY_NAME" },
        { text: trans("variable_company_phone"), value: "COMPANY_PHONE" },
        { text: trans("variable_company_address"), value: "COMPANY_ADDRESS" },
        { text: trans("variable_product_id"), value: "PRODUCT_ID" },
        { text: trans("variable_product_name"), value: "PRODUCT_NAME" },
        {
          text: trans("variable_product_description"),
          value: "PRODUCT_DESCRIPTION",
        },
        { text: trans("variable_product_price"), value: "PRODUCT_PRICE" },
        {
          text: trans("variable_product_quantity"),
          value: "PRODUCT_QUANTITY",
        },
      ];
    },
    getEmailSubjectVariables() {
      if (this.editorModalSelectedRecord.category === "account") {
        return this.getEmailAccountVariables;
      }
      if (this.editorModalSelectedRecord.category === "newsletter") {
        return this.getEmailNewsletterVariables;
      }
      if (
        this.editorModalSelectedRecord.category ===
        "simple-store-product-transaction"
      ) {
        return this.getEmailSimpleStoreVariables;
      }

      return this.getAllVariables;
    },
    getEmailVariables() {
      if (this.editorModalSelectedRecord.category === "account") {
        return this.getEmailAccountVariables;
      }
      if (this.editorModalSelectedRecord.category === "newsletter") {
        return this.getEmailNewsletterVariables;
      }
      if (
        this.editorModalSelectedRecord.category ===
        "simple-store-product-transaction"
      ) {
        return this.getEmailSimpleStoreVariables;
      }

      const allVariables = this.$helpers.cloneObject(this.getAllVariables);

      if (this.editorModalSelectedRecord.category === "booking") {
        allVariables.push({
          text: trans("variable_product_list"),
          value: "PRODUCT_LIST",
        });

        if (
          this.editorModalSelectedRecord.receiver === "client" &&
          (this.editorModalSelectedRecord.type === "created" ||
            this.editorModalSelectedRecord.type === "updated")
        ) {
          allVariables.push({
            text: trans("add_to_calendar_variable_title"),
            value: "ADD_TO_CALENDAR_BUTTONS",
          });
        }

        if (this.editorModalSelectedRecord.receiver === "client") {
          allVariables.push({
            text: trans("manage_booking_button_variable_title"),
            value: "MANAGE_CUSTOMER_BOOKING_BUTTON",
          });
        }

        if (this.editorModalSelectedRecord.receiver === "tenant") {
          allVariables.push({
            text: trans("manage_bookings_button_variable_title"),
            value: "MANAGE_EMPLOYEE_BOOKING_BUTTON",
          });
        }
      }

      return allVariables;
    },
    getAllVariables() {
      return [
        { text: trans("variable_customer_id"), value: "CUSTOMER_ID" },
        {
          text: trans("variable_customer_full_name"),
          value: "CUSTOMER_FULL_NAME",
        },
        { text: trans("variable_customer_name"), value: "CUSTOMER_NAME" },
        { text: trans("variable_customer_surname"), value: "CUSTOMER_SURNAME" },
        { text: trans("variable_customer_email"), value: "CUSTOMER_EMAIL" },
        { text: trans("variable_customer_phone"), value: "CUSTOMER_PHONE" },
        { text: trans("variable_booking_id"), value: "BOOKING_ID" },
        {
          text: trans("variable_booking_start_date"),
          value: "BOOKING_START_DATE",
        },
        {
          text: trans("variable_booking_start_time"),
          value: "BOOKING_START_TIME",
        },
        { text: trans("variable_booking_end_date"), value: "BOOKING_END_DATE" },
        { text: trans("variable_booking_end_time"), value: "BOOKING_END_TIME" },
        {
          text: trans("variable_booking_time_zone"),
          value: "BOOKING_TIME_ZONE",
        },
        {
          text: trans("variable_booking_recurrence_description"),
          value: "BOOKING_RECURRENCE_DESCRIPTION",
        },
        {
          text: trans("additional_information_after_booking_title"),
          value: "ADDITIONAL_INFORMATION_AFTER_BOOKING",
        },
        {
          text: trans("awaiting_booking_payment_time"),
          value: "BOOKING_AWAITING_PAYMENT_FULL_TIME",
        },
        {
          text: trans("variable_online_meeting_url"),
          value: "BOOKING_ONLINE_MEETING_URL",
        },
        { text: trans("variable_booking_location"), value: "BOOKING_LOCATION" },
        {
          text: trans("variable_booking_control_url"),
          value: "BOOKING_CONTROL_URL",
        },
        {
          text: trans("variable_booking_created_by_user_id"),
          value: "BOOKING_CREATED_BY_USER_ID",
        },
        {
          text: trans("variable_booking_updated_by_user_id"),
          value: "BOOKING_UPDATED_BY_USER_ID",
        },
        {
          text: trans("variable_booking_created_by"),
          value: "BOOKING_CREATED_BY",
        },
        {
          text: trans("variable_booking_updated_by"),
          value: "BOOKING_UPDATED_BY",
        },
        {
          text: trans("variable_booking_custom_fields"),
          value: "BOOKING_CUSTOM_FIELDS",
        },
        { text: trans("variable_service_id"), value: "SERVICE_ID" },
        { text: trans("variable_service_name"), value: "SERVICE_NAME" },
        { text: trans("variable_booking_price"), value: "BOOKING_PRICE" },
        { text: trans("variable_company_name"), value: "COMPANY_NAME" },
        { text: trans("variable_company_phone"), value: "COMPANY_PHONE" },
        { text: trans("variable_company_address"), value: "COMPANY_ADDRESS" },
        { text: trans("variable_booking_status"), value: "BOOKING_STATUS" },
        {
          text: trans("variable_booking_payment_status"),
          value: "BOOKING_PAYMENT_STATUS",
        },
        { text: trans("variable_employee_id"), value: "EMPLOYEE_ID" },
        { text: trans("variable_employee_name"), value: "EMPLOYEE_NAME" },
        { text: trans("variable_employee_surname"), value: "EMPLOYEE_SURNAME" },
        {
          text: trans("variable_employee_full_name"),
          value: "EMPLOYEE_FULL_NAME",
        },
        { text: trans("variable_employee_email"), value: "EMPLOYEE_EMAIL" },
        { text: trans("variable_employee_phone"), value: "EMPLOYEE_PHONE" },
      ];
    },
  },
  watch: {
    editorModalPreviewContent() {
      this.reloadInputs();
    },
    emailSubject(val) {
      EditorContentController.setContent("email", "subject", val);
    },
    emailText(val) {
      EditorContentController.setContent("email", "text", val);
    },
    smsText(val) {
      EditorContentController.setContent("sms", "text", val);
    },
    pushSubject(val) {
      EditorContentController.setContent("push", "subject", val);
    },
    pushText(val) {
      EditorContentController.setContent("push", "text", val);
    },
  },
  created() {
    this.reloadInputs();
  },
  methods: {
    ...mapActions({
      setEditorModalVisible: "notification/setEditorModalVisible",
    }),
    reloadInputs() {
      if (this.editorModalSelectedRecord.email) {
        this.emailSubject = this.editorModalPreviewContent.email.subject;
        this.emailText = this.editorModalPreviewContent.email.text;
      }

      if (this.editorModalSelectedRecord.push) {
        this.pushSubject = this.editorModalPreviewContent.push.subject;
        this.pushText = this.editorModalPreviewContent.push.text;
      }

      if (this.editorModalSelectedRecord.sms) {
        this.smsText = this.editorModalPreviewContent.sms.text;
      }
    },
    counterValue(value) {
      return this.$options.filters.tenantVariable(value).length;
    },
    counterSMSValue(value) {
      return this.$options.filters.tenantVariable(value).length;
    },
    insertTextOnCursorPosition(text, refName, localVariableName) {
      insertTextOnCursorPosition(this, text, refName, localVariableName);
    },
    insertTextToHTMLTextarea(text, refName) {
      insertTextToHTMLTextarea(this, text, refName);
    },
    clearAllNotifications() {
      this.openConfirmDialog({
        type: sharedConfirmDialogTypes.COMMON,
        title: this.$trans("notification_template_editor_sidebar_restore_all"),
        importantInformation: this.$trans(
          "notification_template_editor_sidebar_restore_all_information",
        ),
        confirmationText: this.$trans(
          "notification_template_editor_sidebar_restore_all_button",
        ),
        confirmAction: () => {
          this.setCommonDialogLoader(true);

          EditorContentController.notificationTemplatesAPIAction("delete")
            .then(() => {
              this.closeConfirmDialog();
              this.setEditorModalVisible(false);
            })
            .catch((error) => {
              errorNotification("error_occurred", error);
            })
            .finally(() => {
              this.setCommonDialogLoader(false);
            });
        },
      });
    },
  },
});
</script>

<style lang="scss">
.notification-template-editor-sidebar .v-textarea textarea {
  overflow-y: scroll;
  overflow-x: hidden;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
}
</style>
