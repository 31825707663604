<template>
  <v-container
    id="notification-templates-list"
    fluid
    tag="section"
    class="mb-16"
  >
    <template v-if="!isLoading">
      <v-row>
        <v-col>
          <calendesk-information-message>
            {{ $trans("notification_info") }}
          </calendesk-information-message>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="text-h5">
          {{ $trans("notification_templates_list_for_client") }}
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <notification-template-data-table :data="clientsData" />
        </v-col>
      </v-row>
      <v-row class="mt-8">
        <v-col>
          <span class="text-h5">{{
            $trans("notification_templates_list_for_tenant")
          }}</span>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <calendesk-information-message>
            {{ $trans("notification_templates_list_for_tenant_info") }}
          </calendesk-information-message>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <notification-template-data-table :data="tenantsData" />
        </v-col>
      </v-row>
      <notification-template-editor />
    </template>
    <template v-else>
      <v-row justify="center">
        <v-col class="d-flex align-center justify-center">
          <v-progress-circular color="primary" indeterminate />
        </v-col>
      </v-row>
    </template>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { errorNotification } from "@/lib/calendesk-js-library/tools/notification";
import NotificationTemplateDataTable from "@/views/dashboard/pages/Notifications/NotificationTemplates/components/NotificationTemplateDataTable.vue";
import NotificationTemplateEditor from "@/views/dashboard/pages/Notifications/NotificationTemplateEditor/NotificationTemplateEditor.vue";
import CalendeskInformationMessage from "@/lib/calendesk-js-library/components/CalendeskInformationMessage.vue";

export default {
  name: "NotificationTemplatesList",
  components: {
    CalendeskInformationMessage,
    NotificationTemplateEditor,
    NotificationTemplateDataTable,
  },
  data() {
    return {
      isLoading: true,
      bookingNotificationTypes: ["created", "updated", "deleted", "reminder"],
      emailAccountToClientNotificationTypes: ["activate", "password-reset"],
      emailNewsletterToClientNotificationTypes: ["subscribe"],
      emailSimpleStoreProductTransactionNotificationTypes: ["paid"],
    };
  },
  computed: {
    ...mapGetters({
      types: "notification/getTypes",
      templates: "notification/getTemplates",
    }),
    clientsData() {
      if (this.types && this.templates) {
        const bookingPrepayment = this.getDataForReceiver(
          ["prepayment"],
          "booking",
          "client",
          true,
          false,
          false,
        );
        const bookings = this.getDataForReceiver(
          this.bookingNotificationTypes,
          "booking",
          "client",
          true,
          true,
          this.$config("customer_mobile_app_enabled"),
        );
        const account = this.getDataForReceiver(
          this.emailAccountToClientNotificationTypes,
          "account",
          "client",
          true,
          false,
          false,
        );
        const newsletter = this.getDataForReceiver(
          this.emailNewsletterToClientNotificationTypes,
          "newsletter",
          "client",
          true,
          false,
          false,
        );
        const simpleStoreProduct = this.getDataForReceiver(
          this.emailSimpleStoreProductTransactionNotificationTypes,
          "simple-store-product-transaction",
          "client",
          true,
          false,
          false,
        );

        return [].concat(
          bookingPrepayment,
          bookings,
          account,
          newsletter,
          simpleStoreProduct,
        );
      }

      return [];
    },
    tenantsData() {
      if (this.types && this.templates) {
        const bookings = this.getDataForReceiver(
          this.bookingNotificationTypes,
          "booking",
          "tenant",
          true,
          true,
          true,
        );
        const simpleStoreProduct = this.getDataForReceiver(
          this.emailSimpleStoreProductTransactionNotificationTypes,
          "simple-store-product-transaction",
          "tenant",
          true,
          false,
          false,
        );

        return [].concat(bookings, simpleStoreProduct);
      }

      return [];
    },
  },
  created() {
    this.isLoading = true;
    Promise.all([this.fetchTypes(), this.fetchTemplates()])
      .catch((error) => {
        errorNotification("error_occurred", error);
      })
      .finally(() => {
        this.isLoading = false;
      });
  },
  methods: {
    ...mapActions({
      fetchTypes: "notification/fetchTypes",
      fetchTemplates: "notification/fetchTemplates",
    }),
    getDataForReceiver(types, category, receiver, email, sms, push) {
      const result = [];
      types.forEach((type) => {
        const templates = this.templates.filter((template) =>
          template.type.includes(`${category}.${type}.${receiver}`),
        );

        const record = {
          category,
          type,
          receiver,
          display_name: this.$trans(`${category}_${type}`),
          display_info: this.$trans(`${category}_${type}_info`),
          templates: null,
          email,
          sms,
          push,
        };

        if (templates.length > 0) {
          record.templates = templates;
        }

        result.push(record);
      });

      return result;
    },
  },
};
</script>
