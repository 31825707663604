<template>
  <v-dialog
    ref="dialog"
    v-model="dialog"
    persistent
    :width="390"
    :fullscreen="!$vuetify.breakpoint.smAndUp"
    transition="scale-transition"
  >
    <v-card>
      <v-card-title>
        <span v-if="updatingRules">{{
          $trans("edit_availability_schedule_exception_title")
        }}</span>
        <span v-else>{{
          $trans("add_availability_schedule_exception_title")
        }}</span>
      </v-card-title>
      <v-divider />
      <v-card-text class="pa-2 px-4 full-width">
        <v-container fluid>
          <v-row>
            <v-col class="px-0 text-h6">
              {{ $trans("select_availability_dates_info") }}
            </v-col>
          </v-row>
          <v-row>
            <v-col class="d-flex align-center justify-center">
              <v-date-picker
                v-model="selectedCalendarDates"
                first-day-of-week="1"
                no-title
                multiple
                full-width
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col class="px-0 text-h6">
              <span>{{ $trans("select_availability_hours_info") }}</span>
              <copy-hours-button @copyHoursFromWeekDay="copyHoursFromWeekDay" />
            </v-col>
          </v-row>
          <v-row
            v-if="!selectedCalendarHours || selectedCalendarHours.length === 0"
          >
            <v-col>
              <v-btn small text class="primary" @click.native="addHour">
                <v-icon>$plus</v-icon>
                {{ $trans("add") }}
              </v-btn>
            </v-col>
          </v-row>
          <v-row v-for="(item, index) in selectedCalendarHours" :key="index">
            <v-col class="d-flex align-center justify-center pa-0 ma-0">
              <div class="mr-2 my-2">
                <select-time-picker
                  v-model="item.start_time"
                  dense
                  @input="item.start_time = $event"
                />
              </div>
              <select-time-picker
                v-model="item.end_time"
                dense
                :min="item.start_time"
                allow-setting-midnight
                max="24:00"
                @input="item.end_time = $event"
              />
              <v-btn icon small text class="mx-1 ml-2" @click.native="addHour">
                <v-icon>$plus</v-icon>
              </v-btn>
              <v-btn
                v-if="selectedCalendarHours.length > 1"
                icon
                small
                text
                @click.native="deleteHour(index)"
              >
                <v-icon>$trash-default</v-icon>
              </v-btn>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="px-0 mx-0">
              <conditions-manager
                :rule="getRuleMock"
                @open="openAvailabilityRuleConditionsDialog"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col class="d-flex justify-end">
              <v-btn text color="light" @click="closeDialog(false)">
                {{ $trans("cancel") }}
              </v-btn>
              <v-btn
                text
                color="primary"
                :disabled="
                  !selectedCalendarDates || selectedCalendarDates.length === 0
                "
                @click="closeDialog(true)"
              >
                {{ $trans("ok") }}
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>
import {
  addMinutes,
  parseTime,
} from "@/lib/calendesk-js-library/tools/helpers";
import SelectTimePicker from "@/views/dashboard/pages/Calendar/components/SelectTimePicker.vue";
import CopyHoursButton from "@/components/Dialogs/AvailabilityScheduleDialog/components/CopyHoursButton.vue";
import ConditionsManager from "@/components/Dialogs/AvailabilityScheduleDialog/components/ConditionsManager.vue";

export default {
  components: { ConditionsManager, CopyHoursButton, SelectTimePicker },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    calendarServiceIds: {
      type: Array,
      default: () => [],
    },
    calendarLocationIds: {
      type: Array,
      default: () => [],
    },
    calendarDates: {
      type: Array,
      default: () => [],
    },
    calendarHours: {
      type: Array,
      default: () => [],
    },
    updatingRules: {
      type: Array,
      default: null,
    },
  },
  data() {
    return {
      dialog: false,
      selectedCalendarDates: [],
      selectedCalendarHours: [],
      selectedCalendarServiceIds: [],
      selectedCalendarLocationIds: [],
    };
  },
  computed: {
    getRuleMock() {
      return {
        service_ids: this.selectedCalendarServiceIds,
        location_ids: this.selectedCalendarLocationIds,
      };
    },
  },
  watch: {
    value(val) {
      this.dialog = val;
    },
    dialog(val) {
      this.$emit("input", val);
    },
    calendarDates(val) {
      this.selectedCalendarDates = val;
    },
    calendarHours(val) {
      this.selectedCalendarHours = val;
    },
    calendarServiceIds(val) {
      this.selectedCalendarServiceIds = val;
    },
    calendarLocationIds(val) {
      this.selectedCalendarLocationIds = val;
    },
  },
  methods: {
    addHour() {
      if (this.selectedCalendarHours.length > 0) {
        const last =
          this.selectedCalendarHours[this.selectedCalendarHours.length - 1];

        const parsedEndTime = parseTime(last.end_time);
        this.selectedCalendarHours.push({
          start_time: last.end_time,
          end_time: addMinutes(last.end_time, parsedEndTime <= 1320 ? 60 : 5),
        });
      } else {
        this.selectedCalendarHours.push({
          start_time: this.$config("calendar_time").from,
          end_time: this.$config("calendar_time").to,
        });
      }
    },
    deleteHour(removeIndex) {
      this.selectedCalendarHours = this.selectedCalendarHours.filter(
        (item, index) => index !== removeIndex,
      );
    },
    openAvailabilityRuleConditionsDialog() {
      this.$emit(
        "openAvailabilityRuleConditionsDialog",
        this.updatingRules && this.updatingRules.length > 0
          ? this.updatingRules[0]
          : this.getRuleMock,
      );
    },
    closeDialog(commit) {
      this.$emit("close", {
        isAdding: !this.updatingRules && commit,
        isUpdating: !!this.updatingRules && commit,
        updatingRules: this.updatingRules,
        selectedCalendarDates: this.selectedCalendarDates,
        selectedCalendarHours: this.selectedCalendarHours,
      });
    },
    copyHoursFromWeekDay(weekday) {
      this.$emit("copyHoursFromWeekDay", weekday);
    },
  },
};
</script>
