import {
  mdiDatabase,
  mdiAccountCircle,
  mdiAccountGroup,
  mdiAccountMultiple,
  mdiAccountPlus,
  mdiAccountReactivate,
  mdiAccountSettings,
  mdiAlertCircle,
  mdiApplication,
  mdiArrowDownBold,
  mdiArrowLeft,
  mdiArrowRight,
  mdiBlockHelper,
  mdiBrush,
  mdiCalendar,
  mdiCalendarAccount,
  mdiCalendarMonth,
  mdiCalendarMultiselect,
  mdiCalendarRange,
  mdiCalendarSync,
  mdiCamera,
  mdiCart,
  mdiCashMultiple,
  mdiCellphone,
  mdiCellphoneMessage,
  mdiChairSchool,
  mdiCheck,
  mdiCheckboxMultipleMarkedOutline,
  mdiCheckBoxOutline,
  mdiCheckCircle,
  mdiChevronDoubleLeft,
  mdiChevronDoubleRight,
  mdiChevronDown,
  mdiChevronLeft,
  mdiChevronRight,
  mdiChevronUp,
  mdiCircle,
  mdiClipboardAccount,
  mdiClockOutline,
  mdiClose,
  mdiCloseBoxOutline,
  mdiCloseCircle,
  mdiCloudDownload,
  mdiCodeTags,
  mdiCog,
  mdiConnection,
  mdiContentCopy,
  mdiContentCut,
  mdiContentPaste,
  mdiContentSave,
  mdiCreditCard,
  mdiCrop,
  mdiCropFree,
  mdiCropLandscape,
  mdiCropSquare,
  mdiDelete,
  mdiDeleteCircle,
  mdiDesktopMac,
  mdiDotsVertical,
  mdiDragHorizontalVariant,
  mdiEmail,
  mdiEye,
  mdiEyeOff,
  mdiFacebook,
  mdiFileImage,
  mdiFilterVariant,
  mdiFire,
  mdiFlipHorizontal,
  mdiFlipVertical,
  mdiFormatAlignCenter,
  mdiFormatAlignJustify,
  mdiFormatAlignLeft,
  mdiFormatAlignRight,
  mdiFormatBold,
  mdiFormatColorHighlight,
  mdiFormatColorMarkerCancel,
  mdiFormatColorText,
  mdiFormatHeader1,
  mdiFormatHeader2,
  mdiFormatHeader3,
  mdiFormatHeader4,
  mdiFormatHeader5,
  mdiFormatHeader6,
  mdiFormatItalic,
  mdiFormatListBulleted,
  mdiFormatPageBreak,
  mdiFormatParagraph,
  mdiFormatQuoteOpen,
  mdiFormatSize,
  mdiFormatStrikethrough,
  mdiFormatUnderline,
  mdiGenderMaleFemale,
  mdiGoogleHangouts,
  mdiHeart,
  mdiHelpCircle,
  mdiHome,
  mdiImage,
  mdiImageMultiple,
  mdiInfinity,
  mdiInformationOutline,
  mdiInstagram,
  mdiLink,
  mdiLinkOff,
  mdiListStatus,
  mdiLock,
  mdiLockOpenVariant,
  mdiLogout,
  mdiMagnify,
  mdiMagnifyMinusOutline,
  mdiMagnifyPlusOutline,
  mdiMapMarker,
  mdiMenu,
  mdiMenuDown,
  mdiMinus,
  mdiNavigation,
  mdiOpenInNew,
  mdiOrderBoolAscendingVariant,
  mdiOrderNumericAscending,
  mdiPalette,
  mdiPencil,
  mdiPercent,
  mdiPhone,
  mdiPlus,
  mdiPlusCircle,
  mdiPlusCircleOutline,
  mdiPuzzle,
  mdiRedo,
  mdiReplay,
  mdiRocketLaunch,
  mdiRotateLeft,
  mdiRotateRight,
  mdiSend,
  mdiSizeL,
  mdiSizeM,
  mdiSizeS,
  mdiSizeXl,
  mdiSquare,
  mdiStore,
  mdiSwapVertical,
  mdiSync,
  mdiTabletIpad,
  mdiTicketAccount,
  mdiTrendingNeutral,
  mdiTuneVertical,
  mdiTwitter,
  mdiUndo,
  mdiUnfoldLessHorizontal,
  mdiUnfoldMoreHorizontal,
  mdiVideoBox,
  mdiViewDashboard,
  mdiYoutube,
  mdiCalendarLock,
  mdiArrowLeftRightBold,
  mdiBasketOutline,
  mdiPublish,
  mdiPlaylistPlus,
  mdiTranslate,
} from "@mdi/js";

const materialDesignIcons = {
  database: mdiDatabase,
  translate: mdiTranslate,
  "playlist-plus": mdiPlaylistPlus,
  publish: mdiPublish,
  basket: mdiBasketOutline,
  "arrow-lef-right": mdiArrowLeftRightBold,
  "calendar-lock": mdiCalendarLock,
  lock: mdiLock,
  unlock: mdiLockOpenVariant,
  status: mdiListStatus,
  send: mdiSend,
  availability: mdiCalendarMultiselect,
  fold: mdiUnfoldLessHorizontal,
  unfold: mdiUnfoldMoreHorizontal,
  "open-in-new": mdiOpenInNew,
  connection: mdiConnection,
  cash: mdiCashMultiple,
  "form-fields": mdiOrderBoolAscendingVariant,
  paste: mdiContentPaste,
  "video-box": mdiVideoBox,
  "calendar-sync": mdiCalendarSync,
  calendar: mdiCalendar,
  "calendar-range": mdiCalendarRange,
  password: mdiLock,
  filter: mdiFilterVariant,
  account: mdiAccountCircle,
  "arrow-right": mdiArrowRight,
  "arrow-left": mdiArrowLeft,
  "dots-vertical": mdiDotsVertical,
  "account-multiple": mdiAccountMultiple,
  email: mdiEmail,
  home: mdiHome,
  info: mdiInformationOutline,
  "eye-on": mdiEye,
  "eye-off": mdiEyeOff,
  alert: mdiAlertCircle,
  check: mdiCheck,
  chevron_left: mdiChevronLeft,
  chevron_right: mdiChevronRight,
  plus: mdiPlus,
  "plus-circle": mdiPlusCircle,
  "plus-circle-outline": mdiPlusCircleOutline,
  desktop: mdiDesktopMac,
  tablet: mdiTabletIpad,
  smartphone: mdiCellphone,
  undo: mdiUndo,
  redo: mdiRedo,
  zoom_plus: mdiMagnifyPlusOutline,
  zoom_minus: mdiMagnifyMinusOutline,
  brush: mdiBrush,
  pencil: mdiPencil,
  file: mdiFileImage,
  image: mdiImage,
  reset: mdiSync,
  crop: mdiCrop,
  rotate: mdiReplay,
  "crop-free": mdiCropFree,
  "crop-landscape": mdiCropLandscape,
  "crop-square": mdiCropSquare,
  "rotate-left": mdiRotateLeft,
  "rotate-right": mdiRotateRight,
  "flip-vertical": mdiFlipVertical,
  "flip-horizontal": mdiFlipHorizontal,
  clock: mdiClockOutline,
  drag: mdiDragHorizontalVariant,
  trash: mdiDeleteCircle,
  "trash-default": mdiDelete,
  swap: mdiSwapVertical,
  magnify: mdiMagnify,
  close: mdiClose,
  "close-circle": mdiCloseCircle,
  "check-circle": mdiCheckCircle,
  dot: mdiCircle,
  palette: mdiPalette,
  "chevron-up": mdiChevronUp,
  "chevron-down": mdiChevronDown,
  "chevron-left": mdiChevronLeft,
  "chevron-right": mdiChevronRight,
  "chevron-double-right": mdiChevronDoubleRight,
  "chevron-double-left": mdiChevronDoubleLeft,
  "menu-down": mdiMenuDown,
  "gender-male-female": mdiGenderMaleFemale,
  heart: mdiHeart,
  settings: mdiAccountSettings,
  store: mdiStore,
  trending: mdiTrendingNeutral,
  "block-helper": mdiBlockHelper,
  dashboard: mdiViewDashboard,
  "clipboard-account": mdiClipboardAccount,
  plugins: mdiPuzzle,
  "image-multiple": mdiImageMultiple,
  cog: mdiCog,
  "tune-vertical": mdiTuneVertical,
  twitter: mdiTwitter,
  facebook: mdiFacebook,
  download: mdiCloudDownload,
  "content-cut": mdiContentCut,
  "calendar-month": mdiCalendarMonth,
  "chair-school": mdiChairSchool,
  phone: mdiPhone,
  logout: mdiLogout,
  navigation: mdiNavigation,
  "cell-phone-message": mdiCellphoneMessage,
  fire: mdiFire,
  "close-box-outline": mdiCloseBoxOutline,
  "check-box-outline": mdiCheckBoxOutline,
  infinity: mdiInfinity,
  rocket: mdiRocketLaunch,
  menu: mdiMenu,
  "size-s": mdiSizeS,
  "size-m": mdiSizeM,
  "size-l": mdiSizeL,
  "size-xl": mdiSizeXl,
  minus: mdiMinus,
  chevron_down: mdiChevronDown,
  chevron_up: mdiChevronUp,
  save: mdiContentSave,
  dotsVertical: mdiDotsVertical,
  addUser: mdiAccountPlus,
  menu_down: mdiMenuDown,
  marker: mdiMapMarker,
  mail: mdiEmail,
  arrow_down: mdiArrowDownBold,
  fb: mdiFacebook,
  yt: mdiYoutube,
  instagram: mdiInstagram,
  mobile: mdiCellphone,
  link: mdiLink,
  "link-off": mdiLinkOff,
  camera: mdiCamera,
  "format-bold": mdiFormatBold,
  "format-italic": mdiFormatItalic,
  "format-strikethrough": mdiFormatStrikethrough,
  "format-paragraph": mdiFormatParagraph,
  "format-header-1": mdiFormatHeader1,
  "format-header-2": mdiFormatHeader2,
  "format-header-3": mdiFormatHeader3,
  "format-header-4": mdiFormatHeader4,
  "format-header-5": mdiFormatHeader5,
  "format-header-6": mdiFormatHeader6,
  "format-list-bulleted": mdiFormatListBulleted,
  "order-numeric-ascending": mdiOrderNumericAscending,
  "code-tags": mdiCodeTags,
  "format-quote-open": mdiFormatQuoteOpen,
  "format-color-highlight": mdiFormatColorHighlight,
  "format-color-highlight-cancel": mdiFormatColorMarkerCancel,
  "format-align-left": mdiFormatAlignLeft,
  "format-align-right": mdiFormatAlignRight,
  "format-align-center": mdiFormatAlignCenter,
  "format-align-justify": mdiFormatAlignJustify,
  "format-underline": mdiFormatUnderline,
  "format-page-break": mdiFormatPageBreak,
  "format-color-text": mdiFormatColorText,
  square: mdiSquare,
  "format-size": mdiFormatSize,
  help: mdiHelpCircle,
  cart: mdiCart,
  ticket: mdiTicketAccount,
  application: mdiApplication,
  card: mdiCreditCard,
  copy: mdiContentCopy,
  "google-hangouts": mdiGoogleHangouts,
  percent: mdiPercent,
  subscriptions: mdiAccountReactivate,
  "check-all": mdiCheckboxMultipleMarkedOutline,
  group: mdiAccountGroup,
  "calendar-account": mdiCalendarAccount,
};

export default {
  ...materialDesignIcons,
};
