import moment from "@/lib/calendesk-js-library/plugins/moment";
import helpers, {
  userTimeZone,
} from "@/lib/calendesk-js-library/tools/helpers";

export function handleWdayRule(
  schedule,
  employee,
  rule,
  processedDatesPerEmployee,
  info,
) {
  const debugData = [];
  const events = [];

  const ruleDayOfWeek = helpers.weekdaysSorter2[rule.wday];
  // debugData.push({
  //   step:
  //     "Employee: " +
  //     employee.id +
  //     ", Schedule day: " +
  //     rule.wday +
  //     ", TimeZone: " +
  //     schedule.timezone +
  //     ", Intervals: " +
  //     JSON.stringify(rule.intervals),
  //   value: ruleDayOfWeek,
  // });

  // debugData.push({
  //   step: `Date requested in local calendar: ${info.startStr}`,
  //   value: ruleDayOfWeek,
  // });

  // Calculate the range of dates for the WDAY rule
  const localCalendarWeekStartsAt = moment(info.startStr, helpers.isoFormat)
    .tz(info.timeZone)
    .startOf("isoWeek");

  const targetIsoWeekDay = localCalendarWeekStartsAt
    .isoWeekday(ruleDayOfWeek)
    .startOf("day");
  const targetDateStr = targetIsoWeekDay.format(helpers.dateFormat);

  const scheduleDate = targetIsoWeekDay
    .clone()
    .tz(schedule.timezone, true)
    .startOf("day");

  const processedDatesPerEmployeeDateKey = scheduleDate.format(
    helpers.dateFormat,
  ); // Same consistent format

  // Skip processing if the date has already been processed by a date rule
  if (
    processedDatesPerEmployee[employee.id] &&
    processedDatesPerEmployee[employee.id].has(processedDatesPerEmployeeDateKey)
  ) {
    // debugData.push({
    //   step: "Skipped Day",
    //   value: `Skipped ${targetDateStr} as it is overridden by a date rule`,
    // });
    return { events, debugData };
  } else {
    // debugData.push({
    //   step:
    //     "Employee: " +
    //     employee.id +
    //     ": processedDatesPerEmployee, date: " +
    //     scheduleDate.format(helpers.dateFormat),
    //   value: processedDatesPerEmployee[employee.id],
    // });
    // debugData.push({
    //   step: "Processed Dates",
    //   value: {
    //     employeeId: employee.id,
    //     storedDates: [...processedDatesPerEmployee[employee.id]], // Spread to show all values
    //     checkingDate: processedDatesPerEmployeeDateKey,
    //     hasMatch: processedDatesPerEmployee[employee.id].has(
    //       processedDatesPerEmployeeDateKey,
    //     ),
    //   },
    // });
  }

  // debugData.push({
  //   step: "Target Date for the rule in the local Calendar",
  //   value: targetDateStr + ", TimeZone: " + targetIsoWeekDay.tz(),
  // });

  rule.intervals.forEach((interval) => {
    const intervalResult = processDayWeekIntervals(
      schedule.timezone,
      interval,
      targetIsoWeekDay,
      employee,
      rule.type,
    );
    events.push(...intervalResult.events);
    debugData.push(...intervalResult.debugData);
  });

  return { events, debugData };
}

export function handleDateRule(
  schedule,
  employee,
  rule,
  processedDatesPerEmployee,
  info,
) {
  const debugData = [];
  const events = [];

  const targetDate = moment(rule.date, helpers.dateFormat).tz(info.timeZone);
  // debugData.push({
  //   step:
  //     "Employee: " +
  //     employee.id +
  //     ", Schedule date: " +
  //     rule.date +
  //     ", TimeZone: " +
  //     schedule.timezone +
  //     ", Intervals: " +
  //     JSON.stringify(rule.intervals),
  //   value: null,
  // });

  processedDatesPerEmployee[employee.id].add(
    targetDate.format(helpers.dateFormat),
  );

  rule.intervals.forEach((interval) => {
    const intervalResult = processDateIntervals(
      schedule.timezone,
      interval,
      targetDate,
      employee,
      rule.type,
    );
    events.push(...intervalResult.events);
    debugData.push(...intervalResult.debugData);
  });

  return { events, debugData };
}

function processDateIntervals(
  scheduleTz,
  interval,
  targetDate,
  employee,
  ruleType,
) {
  const debugData = [];
  const events = [];

  // Calculate start and end times in the schedule timezone
  const startInTZ = targetDate
    .clone()
    .tz(scheduleTz, true)
    .startOf("day")
    .add(moment.duration(interval.start_time));

  let endInTZ = targetDate
    .clone()
    .tz(scheduleTz, true)
    .startOf("day")
    .add(moment.duration(interval.end_time));

  if (interval.end_time === "00:00") {
    endInTZ.add(1, "day");
  }

  // debugData.push({
  //   step: "Start/End in Schedule Timezone",
  //   value: `${startInTZ.format("YYYY-MM-DD HH:mm")} / ${endInTZ.format("YYYY-MM-DD HH:mm")} / ${startInTZ.tz()}`,
  // });

  // Convert to user timezone
  const startLocal = startInTZ.clone().tz(userTimeZone());
  const endLocal = endInTZ.clone().tz(userTimeZone());

  // debugData.push({
  //   step: "Start/End in User Timezone",
  //   value: `${startLocal.format("YYYY-MM-DD HH:mm")} / ${endLocal.format("YYYY-MM-DD HH:mm")} / ${startLocal.tz()}`,
  // });

  // Directly push the event with specific start and end times
  events.push({
    start: startLocal.format("YYYY-MM-DDTHH:mm:ss"),
    end: endLocal.format("YYYY-MM-DDTHH:mm:ss"),
    display: "background",
    resourceId: employee.id,
    scheduleRuleType: ruleType,
    employeeId: employee.id,
  });

  return { events, debugData };
}
function processDayWeekIntervals(
  scheduleTz,
  interval,
  targetDate,
  employee,
  ruleType,
) {
  const debugData = [];
  const events = [];

  // Calculate start and end times in the schedule timezone
  const startInTZ = targetDate
    .clone()
    .tz(scheduleTz, true)
    .startOf("day")
    .add(moment.duration(interval.start_time));

  let endInTZ = targetDate
    .clone()
    .tz(scheduleTz, true)
    .startOf("day")
    .add(moment.duration(interval.end_time));

  if (interval.end_time === "00:00") {
    endInTZ.add(1, "day");
  }

  // debugData.push({
  //   step: "Start/End in Schedule Timezone",
  //   value: `${startInTZ.format("YYYY-MM-DD HH:mm")} / ${endInTZ.format("YYYY-MM-DD HH:mm")} / ${startInTZ.tz()}`,
  // });

  // Convert to user timezone
  const startLocal = startInTZ.clone().tz(userTimeZone());
  const endLocal = endInTZ.clone().tz(userTimeZone());

  // debugData.push({
  //   step: "Start/End in User Timezone",
  //   value: `${startLocal.format("YYYY-MM-DD HH:mm")} / ${endLocal.format("YYYY-MM-DD HH:mm")} / ${startLocal.tz()}`,
  // });

  // Slice intervals by local days
  let current = startLocal.clone();
  while (current.isBefore(endLocal, "minute")) {
    const chunkDayOfWeek = current.day(); // 0=Sunday, 1=Monday, ..., 6=Saturday
    const nextMidnight = current.clone().endOf("day").add(1, "millisecond");
    const segmentEnd = moment.min(nextMidnight, endLocal);

    const startStr = current.format("HH:mm");
    let endStr = segmentEnd.format("HH:mm");
    if (segmentEnd.hour() === 0 && segmentEnd.minute() === 0) {
      endStr = "24:00";
    }

    if (segmentEnd.isAfter(current, "minute")) {
      events.push({
        daysOfWeek: [chunkDayOfWeek],
        startTime: startStr,
        endTime: endStr,
        start_time: startStr,
        end_time: endStr,
        display: "background",
        resourceId: employee.id,
        scheduleRuleType: ruleType,
        employeeId: employee.id,
      });
    }

    current = segmentEnd.clone();
    if (
      current.hour() === 0 &&
      current.minute() === 0 &&
      current.second() === 0 &&
      current.millisecond() === 0
    ) {
      current.add(1, "millisecond");
    }
  }

  return { events, debugData };
}
