var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',{attrs:{"no-gutters":_vm.dense}},[_c('v-col',{class:{
      'my-0 py-0 cd-p-0': _vm.dense,
    },attrs:{"cols":"12"}},[_c('div',[_c('div',{staticClass:"font-weight-medium mb-2"},[_vm._v(" "+_vm._s(_vm.$trans("booking_form_custom_fields"))+": ")]),_c('div',_vm._l((_vm.customFields),function(field,index){return _c('div',{key:index,class:{ 'my-2': !_vm.dense }},[(field.label)?_c('div',{staticClass:"font-weight-medium"},[_vm._v(" "+_vm._s(field.label)+": ")]):(field.description)?_c('div',{staticClass:"font-weight-medium"},[(
                _vm.stripHtml(_vm.stripSurroundingPTags(field.description)).length >
                100
              )?_c('v-tooltip',{attrs:{"bottom":"","max-width":"800"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_vm._v(" "+_vm._s(_vm._f("truncate")(_vm._f("stripSurroundingPTags")(_vm._f("stripHtml")(field.description)),100))+": ")])]}}],null,true)},[_c('div',{domProps:{"innerHTML":_vm._s(
                  _vm.sanitizeHtml(_vm.stripSurroundingPTags(field.description))
                )}})]):_c('span',[_vm._v(" "+_vm._s(_vm._f("truncate")(_vm._f("stripSurroundingPTags")(_vm._f("stripHtml")(field.description)),100))+": ")])],1):_vm._e(),(field.type === _vm.bookingDynamicFormTypes.CHECKBOX)?[_c('div',[_vm._v(_vm._s(field.value ? _vm.$trans("yes") : _vm.$trans("no")))])]:(
              field.type === _vm.bookingDynamicFormTypes.FILES &&
              Array.isArray(field.value)
            )?[_c('div',_vm._l((field.value),function(file){return _c('div',{key:file.uuid},[(
                    typeof file === 'object' && Object.keys(file).length > 0
                  )?_c('span',_vm._l((file),function(name,uuid){return _c('span',{key:uuid},[_c('a',{attrs:{"href":_vm.generateFileUrl(uuid),"target":"_blank"}},[_vm._v(" "+_vm._s(name)+" ")])])}),0):_vm._e()])}),0)]:(field.value)?[_c('div',[_vm._v(_vm._s(field.value))])]:_vm._e()],2)}),0)])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }