<template>
  <v-container fluid>
    <v-row
      v-for="(item, index) in selectedIntervals"
      :key="`${item.start_time}-${item.end_time}-${index}-${shouldShowDeleteButton}`"
    >
      <v-col class="d-flex align-center justify-center pa-0 ma-0 py-1">
        <div class="mr-1">
          <select-time-picker
            v-model="item.start_time"
            dense
            hide-icon
            hide-details
            @input="updateInterval(index, 'start_time', $event)"
          />
        </div>
        -
        <div class="ml-1">
          <select-time-picker
            v-model="item.end_time"
            dense
            hide-icon
            hide-details
            :min="item.start_time"
            allow-setting-midnight
            max="24:00"
            @input="updateInterval(index, 'end_time', $event)"
          />
        </div>
        <v-tooltip bottom max-width="400">
          <template #activator="{ on }">
            <v-btn
              v-if="shouldShowDeleteButton"
              class="ml-2"
              icon
              small
              text
              v-on="on"
              @click.native="deleteInterval(index)"
            >
              <v-icon>$trash-default</v-icon>
            </v-btn>
          </template>
          {{ $trans("delete") }}
        </v-tooltip>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="d-flex align-center justify-center">
        <v-tooltip bottom max-width="400">
          <template #activator="{ on }">
            <v-btn
              icon
              small
              text
              class="mx-1 ml-2"
              v-on="on"
              @click.native="addInterval"
            >
              <v-icon>$plus</v-icon>
            </v-btn>
          </template>
          {{ $trans("add_hours_interval_button_tooltip") }}
        </v-tooltip>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import {
  addMinutes,
  cloneObject,
  parseTime,
} from "@/lib/calendesk-js-library/tools/helpers";
import { mapGetters } from "vuex";
import log from "@/lib/calendesk-js-library/tools/log";
import SelectTimePicker from "@/views/dashboard/pages/Calendar/components/SelectTimePicker.vue";

export default {
  name: "TimeRangeInterval",
  components: { SelectTimePicker },
  props: {
    rule: {
      type: Object,
      required: true,
    },
    hideDeleteButtonForOnlyOneInterval: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      selectedRule: null,
      selectedIntervals: [],
    };
  },
  computed: {
    ...mapGetters({
      configuration: "setup/getConfiguration",
    }),
    shouldShowDeleteButton() {
      return (
        !this.hideDeleteButtonForOnlyOneInterval ||
        (this.hideDeleteButtonForOnlyOneInterval &&
          this.selectedIntervals.length > 1)
      );
    },
  },
  watch: {
    rule() {
      this.assignValue();
    },
  },
  created() {
    this.assignValue();
  },
  methods: {
    assignValue() {
      if (this.rule && this.rule.intervals) {
        this.selectedRule = cloneObject(this.rule);
        this.selectedIntervals = this.selectedRule.intervals;
      }
    },
    addInterval() {
      if (this.selectedIntervals.length > 0) {
        const last = this.selectedIntervals[this.selectedIntervals.length - 1];

        const parsedEndTime = parseTime(last.end_time);
        this.selectedIntervals.push({
          start_time: last.end_time,
          end_time: addMinutes(last.end_time, parsedEndTime <= 1320 ? 60 : 5),
        });
      } else {
        this.selectedIntervals.push({
          start_time: this.configuration.calendar_time.from,
          end_time: this.configuration.calendar_time.to,
        });
      }

      this.change();
    },
    deleteInterval(indexToRemove) {
      this.selectedIntervals = this.selectedIntervals.filter(
        (item, index) => index !== indexToRemove,
      );

      this.change();
    },
    updateInterval(indexToUpdate, key, value) {
      // Ensure the key is valid to prevent arbitrary or unintended mutation
      if (["start_time", "end_time"].includes(key)) {
        // Create a new array with the same intervals, except for the updated one
        this.selectedIntervals = this.selectedIntervals.map((item, index) => {
          if (indexToUpdate === index) {
            // Use spread syntax to create a new object with the updated key-value pair
            return { ...item, [key]: value };
          }
          return item;
        });

        this.change();
      } else {
        log.warn(`Invalid key '${key}' for interval update.`);
      }
    },
    change() {
      this.$emit(
        "change",
        cloneObject({
          ...this.selectedRule,
          intervals: [...this.selectedIntervals],
        }),
      );
    },
  },
};
</script>
