<template>
  <v-menu
    v-model="showMenu"
    transition="slide-y-transition"
    :nudge-bottom="dense ? 42 : 57"
    bottom
  >
    <template #activator="{ on, attrs }">
      <v-text-field
        v-model="selectedTime"
        v-mask="'##:##'"
        :disabled="disabled"
        :rules="[min ? checkTimeMin() : true, ...rules]"
        :label="label"
        outlined
        v-bind="attrs"
        :type="type"
        :hide-details="hideDetails"
        :dense="dense"
        :class="{
          'c-time-input-small': hideIcon,
        }"
        @focus="$event.target.select()"
        @blur="onSelect(selectedTime)"
        @keydown.enter.prevent="onEnter"
        v-on="on"
      >
        <template v-if="!hideIcon" #append>
          <v-icon v-on="on"> $clock</v-icon>
        </template>
      </v-text-field>
    </template>
    <v-list class="c-text-field-list">
      <v-list-item-group>
        <v-list-item
          v-for="(item, i) in timeSlots"
          :id="uniqueId + '-list-item-' + i"
          :key="i"
          @click="onSelect(item)"
        >
          <v-list-item-content>
            <v-list-item-title v-text="item" />
          </v-list-item-content>
        </v-list-item>
      </v-list-item-group>
    </v-list>
  </v-menu>
</template>

<script>
import { mapGetters } from "vuex";
import {
  addMinutes,
  generateTimes,
  parseTime,
} from "@/lib/calendesk-js-library/tools/helpers";
import timeInputActions from "@/calendesk/mixins/timeInputActions";

export default {
  name: "SelectTimePicker",
  mixins: [timeInputActions],
  model: {
    prop: "value",
    event: "input",
  },
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    dense: {
      type: Boolean,
      default: false,
    },
    hideIcon: {
      type: Boolean,
      default: false,
    },
    hideDetails: {
      type: [String, Boolean],
      default: "auto",
    },
    label: {
      type: String,
      default: "",
    },
    value: {
      type: String,
      default: "false",
    },
    rules: {
      type: [Array, Object],
      default: () => [],
    },
    max: {
      type: String,
      default: "23:59",
    },
    min: {
      type: String,
      default: "00:00",
    },
    type: {
      type: String,
      default: "",
    },
    allowSettingMidnight: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isValid: true,
      selectedTime: this.value,
    };
  },
  computed: {
    ...mapGetters({
      configuration: "setup/getConfiguration",
    }),
    timeSlots() {
      return generateTimes(this.min, this.max, 15);
    },
  },
  watch: {
    value() {
      this.selectedTime = this.value;
    },
    min() {
      this.onSelect(this.selectedTime);
    },
  },
  methods: {
    isAfterMinTime(value) {
      const minimumTimeObj = this.$moment(this.min, this.$helpers.timeFormat);
      const selectedTimeObj = this.$moment(value, this.$helpers.timeFormat);

      return (
        selectedTimeObj.isSameOrAfter(minimumTimeObj) ||
        (this.allowSettingMidnight && value === "00:00")
      ); // Allow 00:00 if allowSettingMidnight === true;
    },
    checkTimeMin() {
      if (this.min && this.selectedTime) {
        // Handle the case for "00:00" explicitly
        if (this.allowSettingMidnight && this.selectedTime === "00:00") {
          return true; // Allow "00:00" as a valid time
        }

        return parseTime(this.min) > parseTime(this.selectedTime)
          ? this.$trans("incorrect_time")
          : true;
      }
      return true;
    },
    onSelect(item) {
      if (!this.isAfterMinTime(item)) {
        item = addMinutes(this.min, 5);
      }

      this.selectedTime = this.processInput(item);
      this.$emit("input", this.selectedTime);
    },
    onEnter() {
      this.onSelect(this.selectedTime);
      this.showMenu = false;
    },
  },
};
</script>
<style lang="scss" scoped>
.c-text-field-list {
  height: 200px;
  overflow-y: auto;
}

.c-time-input-small {
  width: 65px;
  max-width: 65px;
  min-width: 65px;
}
</style>
