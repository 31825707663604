<template>
  <v-navigation-drawer
    v-if="loggedUser"
    id="core-navigation-drawer"
    v-model="drawer"
    app
    mini-variant-width="72"
    mobile-break="960"
    v-bind="$attrs"
    width="240"
    :mini-variant="displayMiniDrawer"
    class="mobile-drawer-margin"
    :class="{ 'display-full-drawer': !displayMiniDrawer }"
    style="height: 100%"
  >
    <div
      class="drawer"
      @mouseenter="mouseEnterDrawerHandle"
      @mouseleave="mouseLeaveDrawerHandle"
    >
      <v-list
        dense
        nav
        dark
        :height="$vuetify.breakpoint.height - 100"
        :class="{ 'mini-drawer': displayMiniDrawer }"
      >
        <v-list-item class="list-item-logo">
          <v-list-item-avatar v-if="canDisplaySymbolLogo">
            <v-img :src="symbolLogo" contain />
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title>
              <v-img
                :src="fullLogo"
                :max-height="getFullLogoMaxHeight"
                contain
              />
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item class="my-2" @click="goToProfile">
          <v-list-item-avatar>
            <v-img :src="getAvatarSrc(loggedUser)" />
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title
              >{{ loggedUser.name }} {{ loggedUser.surname }}</v-list-item-title
            >
            <v-list-item-subtitle>{{ loggedUser.email }}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <v-divider dark class="my-2" />
        <v-list-item
          class="navigation__item"
          active-class="navigation__item--active"
          exact
          @click="$root.$emit('openEventModal')"
        >
          <v-list-item-avatar>
            <div
              class="d-flex align-center justify-center navigation__item__avatar"
            >
              <v-icon size="24">$plus</v-icon>
            </div>
          </v-list-item-avatar>

          <v-list-item-content>
            <v-list-item-title>{{ $trans("add_booking") }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item
          class="navigation__item"
          active-class="navigation__item--active"
          exact
          @click="openHelpCenter"
        >
          <v-list-item-avatar>
            <div
              class="d-flex align-center justify-center navigation__item__avatar"
            >
              <v-icon size="24"> $help</v-icon>
            </div>
          </v-list-item-avatar>

          <v-list-item-content>
            <v-list-item-title>{{ $trans("help") }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <template v-for="item in sidebarItems">
          <template v-if="item.children && hasPermissions(item.permissions)">
            <v-list-group
              :key="item.title"
              :prepend-icon="item.icon"
              append-icon="$menu-down"
              no-action
              :group="item.to"
              :class="{ 'group-active': $route.path.includes(item.to) }"
            >
              <template #activator>
                <v-list-item-content>
                  <v-list-item-title class="text-wrap" v-text="item.title" />
                </v-list-item-content>
              </template>

              <v-list-item
                v-for="child in filteredSubmenuChildren(item.children)"
                :key="child.title"
                link
                :to="child.to"
                exact
                exact-active-class="item-active"
                @click="child.action ? child.action() : () => {}"
              >
                <v-list-item-content>
                  <v-list-item-title class="text-wrap" v-text="child.title" />
                </v-list-item-content>
              </v-list-item>
            </v-list-group>
          </template>
          <v-list-item
            v-else-if="hasPermissions(item.permissions)"
            :key="item.title"
            link
            :to="
              item.tabsId ? getCurrentTabsLink(item.tabsId, item.to) : item.to
            "
            :href="item.href"
            class="navigation__item"
            :class="{
              'navigation__item--active':
                item && item.to && $route.name === item.to.name,
            }"
            exact
            @click="item.action ? item.action() : () => {}"
          >
            <v-list-item-avatar>
              <div
                class="d-flex align-center justify-center navigation__item__avatar"
              >
                <v-icon size="24">
                  {{ item.icon }}
                </v-icon>
              </div>
            </v-list-item-avatar>

            <v-list-item-content>
              <v-list-item-title>{{ item.title }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </template>
      </v-list>
    </div>
    <template #append>
      <div v-if="showPlanButton" class="pa-4">
        <v-btn
          v-if="!getHasAnyPastDueInvoice"
          color="primary"
          dark
          depressed
          block
          small
          @click="goToPlan"
        >
          <span v-if="onTrial">{{ $trans("select_plan") }}</span>
          <span v-else>{{ $trans("change_plan_button_title") }}</span>
        </v-btn>
        <v-btn
          v-else
          color="primary"
          dark
          depressed
          block
          small
          @click="openPastDueDialog"
        >
          {{ $trans("change_plan_button_title") }}
        </v-btn>
      </div>
      <v-list v-if="isBetaVersion" class="py-0">
        <v-list-item class="navigation__bottom-item justify-end">
          <v-list-item-subtitle>
            BETA: {{ getCurrentAppVersion }}
          </v-list-item-subtitle>
        </v-list-item>
      </v-list>
      <v-list class="py-0">
        <v-list-item
          class="navigation__bottom-item justify-end pr-2"
          @click="miniVariantHandle"
        >
          <v-list-item-action class="mr-0">
            <v-icon v-if="displayMiniDrawer" dark> $trending</v-icon>
            <v-icon v-else dark class="icon-rotation"> $trending</v-icon>
          </v-list-item-action>
        </v-list-item>
      </v-list>
    </template>
  </v-navigation-drawer>
</template>

<script>
import { mapGetters } from "vuex";
import roleActions from "@/calendesk/mixins/roleActions";
import sidebarItems from "@/views/dashboard/components/core/Drawer/sidebarItems";
import urls from "@/calendesk/mixins/urls";
import {
  getAvatarSrc,
  getCurrentTabsLink,
  getMediaSrc,
  pushEvent,
} from "@/lib/calendesk-js-library/tools/helpers";
import planActions from "@/calendesk/mixins/planActions";

export default {
  name: "DashboardCoreDrawer",
  mixins: [sidebarItems, roleActions, urls, planActions],
  data: () => ({
    drawerHover: JSON.parse(localStorage.getItem("miniDrawer")),
    drawerMouseHover: false,
  }),
  computed: {
    ...mapGetters({
      currentPlan: "packages/getCurrentTenantPlan",
      getHasAnyPastDueInvoice: "setup/getHasAnyPastDueInvoice",
      adminConfiguration: "setup/getAdminConfiguration",
    }),
    getCurrentAppVersion() {
      return window.APP_VERSION;
    },
    isBetaVersion() {
      return (
        process.env.VUE_APP_BETA_HOSTNAME &&
        window.location.hostname === process.env.VUE_APP_BETA_HOSTNAME
      );
    },
    showPlanButton() {
      return (
        this.loggedUserIsAdmin &&
        !this.displayMiniDrawer &&
        !this.drawerMouseHover &&
        this.currentPlan &&
        this.currentPlan.slug !== this.$helpers.plans.CD_BASE_PRO
      );
    },
    displayMiniDrawer() {
      return this.drawerHover && !this.drawerMouseHover;
    },
    symbolLogo() {
      if (this.hasCompanySymbolLogo) {
        return getMediaSrc(this.adminConfiguration.company_logo_symbol);
      }
      return require("@/assets/logo_symbol_white.svg");
    },
    fullLogo() {
      if (this.hasCompanyFullLogo) {
        return getMediaSrc(this.adminConfiguration.company_logo_full);
      }
      return require("@/assets/logo_text_white.svg");
    },
    getFullLogoMaxHeight() {
      if (this.hasCompanyFullLogo) {
        return null;
      }

      return 15;
    },
    canDisplaySymbolLogo() {
      return (
        (this.hasCompanySymbolLogo && this.hasCompanyFullLogo) ||
        (!this.hasCompanySymbolLogo && !this.hasCompanyFullLogo)
      );
    },
    hasCompanyFullLogo() {
      return (
        this.adminConfiguration && this.adminConfiguration.company_logo_full
      );
    },
    hasCompanySymbolLogo() {
      return (
        this.adminConfiguration && this.adminConfiguration.company_logo_symbol
      );
    },
    drawer: {
      get() {
        return this.$store.state.drawer;
      },
      set(val) {
        this.$store.commit("SET_DRAWER", val);
      },
    },
  },
  methods: {
    getAvatarSrc,
    getCurrentTabsLink,
    openPastDueDialog() {
      this.$root.$emit("openPastDueView", false);
    },
    openHelpCenter() {
      pushEvent("openHelpCenter");
      const locale = localStorage.getItem("locale").toLowerCase();

      if (locale === "pl") {
        window.open(`https://calendesk.com/pl/help/`, "_blank");
      } else {
        window.open(`https://calendesk.com/help/`, "_blank");
      }
    },
    goToPlan() {
      this.$root.$emit("openPlanView", false);
    },
    goToProfile() {
      if (
        this.loggedUser &&
        this.loggedUser.employee &&
        this.loggedUser.employee.id &&
        this.loggedUserCanEditEmployees
      ) {
        this.$router
          .push({
            name: "employee",
            params: { id: this.loggedUser.employee.id },
          })
          .catch(() => {});
      } else {
        this.$router
          .push({ name: "settings", params: { tab: "profile" } })
          .catch(() => {});
      }
    },
    filteredSubmenuChildren(children) {
      return children.filter((child) => this.hasPermissions(child.permissions));
    },
    mouseEnterDrawerHandle() {
      if (this.drawerHover) {
        this.drawerMouseHover = true;
      }
    },
    mouseLeaveDrawerHandle() {
      if (this.drawerHover) {
        this.drawerMouseHover = false;
      }
    },
    miniVariantHandle() {
      if (this.drawerHover) {
        this.drawerHover = false;
        localStorage.setItem("miniDrawer", this.drawerHover);
      } else {
        this.drawerHover = true;
        localStorage.setItem("miniDrawer", this.drawerHover);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
#core-navigation-drawer {
  background: linear-gradient(180deg, #8208cd 0%, #0c96b8 100%) !important;
}

.navigation__item:before {
  background-color: transparent;
}

.navigation__item {
  font-size: 18px;
  opacity: 0.8;
}

.navigation__item--active,
.navigation__item:hover {
  opacity: 1;
}

.navigation__item--active .navigation__item__avatar {
  background: rgba(255, 255, 255, 0.2);
  width: 40px;
  height: 40px;
}

.navigation__bottom-item {
  min-height: 26px;
  height: 26px;
  background: #86cadb;
}

.navigation__item:hover .v-avatar {
  background: rgba(255, 255, 255, 0.2);
}

.icon-rotation {
  transform: rotate(180deg);
  transition: none !important;
}

.v-icon {
  transition: none !important;
}

.list-item-logo {
  height: 56px;

  .v-list-item__avatar {
    border-radius: 0;
  }
}

@media screen and (max-width: 600px) {
  .tooltip-margin-default.drawer-tooltip {
    max-width: calc(100vw - 240px - 3px);
  }
}

@media screen and (max-width: 960px) {
  .drawer {
    margin-bottom: 30px;
  }
}

.drawer-divider {
  border-color: rgba(255, 255, 255, 0.2) !important;
}

.hover:hover {
  cursor: pointer;
}

.font-10-mobile {
  font-size: 10px;
}

.drawer-tooltip {
  padding: 0;
  opacity: 1 !important;
  max-width: 500px;
  pointer-events: auto;
  transition: none !important;
  padding: 0;
  top: -20px !important;
}

.tooltip-margin-mini {
  left: 75px !important;
  margin-left: 0;
  margin-top: 25px;
}

.tooltip-margin-default {
  left: 240px !important;
  margin-left: 3px;
  margin-right: 3px;
  margin-top: 20px;
}

@media screen and (min-width: 960px) {
  .tooltip-margin-default {
    margin-right: 0;
    margin-top: 25px;
  }
}

.configuration-arrow {
  transform: rotate(45deg);
  background-color: rgb(255, 255, 255);
  width: 35px;
  height: 35px;
  left: 235px;
  position: fixed;
  border-radius: 10px !important;
  clip-path: polygon(0 0%, 0 100%, 100% 100%);
}

.configuration-arrow-mini {
  left: 62px;
  top: 80px;
}

.configuration-arrow-default {
  left: 230px;
  top: 94px;
}
</style>
