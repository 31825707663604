<template>
  <v-container class="form__container" fluid>
    <v-form ref="form" @submit.prevent="handleSave">
      <v-row>
        <v-col cols="12" md="6">
          <v-row>
            <v-col cols="12">
              <h6
                class="text-h6"
                v-text="$trans('profile_company_contact_header')"
              />
            </v-col>
            <v-col cols="12" class="px-3">
              <calendesk-information-message>
                {{ $trans("contact_data_info") }}
              </calendesk-information-message>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-text-field
                v-model="settings.companyName"
                :label="$trans('profile_company_name_label_input')"
                :placeholder="$trans('profile_company_name_placeholder_input')"
                outlined
                :rules="[
                  rules.required,
                  rules.minChars(4),
                  rules.maxChars(255),
                ]"
                hide-details="auto"
                validate-on-blur
                :disabled="isLoading"
                class="required-star"
              />
            </v-col>
            <v-col v-if="$config('customer_mobile_app_enabled')" cols="12">
              <v-row no-gutters>
                <v-col cols="5" class="pr-3 pb-6">
                  <v-text-field
                    v-model="settings.companyStreet"
                    :label="
                      $trans('profile_company_contact_street_label_input')
                    "
                    outlined
                    hide-details="auto"
                    validate-on-blur
                    :rules="[rules.maxChars(100)]"
                    :disabled="isLoading"
                  />
                </v-col>
                <v-col cols="3" class="px-3 pb-6">
                  <v-text-field
                    v-model="settings.companyPostalCode"
                    :label="
                      $trans('profile_company_contact_zip_code_label_input')
                    "
                    outlined
                    hide-details="auto"
                    validate-on-blur
                    :rules="[rules.maxChars(15)]"
                    :disabled="isLoading"
                  />
                </v-col>
                <v-col cols="4" class="pl-3 pb-6">
                  <v-text-field
                    v-model="settings.companyCity"
                    :label="$trans('profile_company_contact_city_label_input')"
                    outlined
                    hide-details="auto"
                    validate-on-blur
                    :rules="[rules.maxChars(50)]"
                    :disabled="isLoading"
                  />
                </v-col>
                <v-col cols="12">
                  <c-country-autocomplete
                    v-model="settings.companyCountryIsoCode"
                    :label="
                      $trans('profile_company_contact_country_autocomplete')
                    "
                    :disabled="isLoading"
                  />
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-text-field
                v-model="settings.companyEmail"
                :label="$trans('profile_company_contact_email_label_input')"
                :rules="
                  settings.companyEmail ? [rules.email, rules.maxChars(60)] : []
                "
                outlined
                validate-on-blur
                hide-details="auto"
                :disabled="isLoading"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <phone-input
                v-model="settings.companyPhone"
                :disabled="isLoading"
                :default-country-code="$config('preferred_phone_countries')[0]"
                @enter="handleSave"
              />
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12" md="6">
          <v-row>
            <v-col cols="12" class="text-h6">
              {{ $trans("settings_alphanumeric_sms_sender_title") }}
            </v-col>
            <v-col cols="12" class="px-3">
              <calendesk-information-message>
                {{ $trans("settings_alphanumeric_sms_sender_description") }}
              </calendesk-information-message>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" class="d-flex">
              <v-text-field
                v-model="settings.alphanumericSmsSender"
                :label="$trans('settings_alphanumeric_sms_sender_input_title')"
                :rules="
                  settings.alphanumericSmsSender
                    ? [rules.alphanumericSMSSender, rules.maxChars(11)]
                    : []
                "
                outlined
                validate-on-blur
                :hint="$trans('calendesk_name_example')"
                persistent-hint
                :disabled="isLoading || !canUseAlphanumericSMSSender"
              />
              <v-btn
                v-if="!canUseAlphanumericSMSSender"
                class="mt-2 ml-2"
                icon
                @click="openPackageNotAvailableDialog"
              >
                <v-icon>$edit</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-btn
            color="primary"
            depressed
            type="submit"
            :disabled="isLoading"
            :loading="isLoading"
          >
            {{ $trans("save") }}
          </v-btn>
        </v-col>
      </v-row>
    </v-form>
  </v-container>
</template>

<script>
import { mapActions } from "vuex";
import CalendeskInformationMessage from "@/lib/calendesk-js-library/components/CalendeskInformationMessage.vue";
import {
  alphanumericSMSSender,
  email,
  maxChars,
  minChars,
  required,
  url,
} from "@/lib/calendesk-js-library/forms/validators";
import CCountryAutocomplete from "@/components/Forms/CCountryAutocomplete.vue";
import PhoneInput from "@/components/Forms/PhoneInput/PhoneInput.vue";
import {
  errorNotification,
  successNotification,
} from "@/lib/calendesk-js-library/tools/notification";
import planActions from "@/calendesk/mixins/planActions";
import { pushEvent } from "@/lib/calendesk-js-library/tools/helpers";

export default {
  name: "ContactDataForm",
  components: { PhoneInput, CCountryAutocomplete, CalendeskInformationMessage },
  mixins: [planActions],
  data() {
    return {
      rules: {
        email,
        maxChars,
        minChars,
        url,
        required,
        alphanumericSMSSender,
      },
      settings: {
        companyName: this.$config("company_name"),
        companyCountryIsoCode: this.$config("company_country_iso_code"),
        companyStreet: this.$config("company_street"),
        companyCity: this.$config("company_city"),
        companyPostalCode: this.$config("company_postal_code"),
        companyPhone: this.$config("company_phone"),
        companyEmail: this.$config("company_email"),
        facebook: this.$config("facebook"),
        twitter: this.$config("twitter"),
        instagram: this.$config("instagram"),
        youtube: this.$config("youtube"),
        alphanumericSmsSender: null,
      },
      isLoading: false,
      canUpdateAlphanumericSenderSMSSetting: false,
    };
  },
  mounted() {
    this.canUpdateAlphanumericSenderSMSSetting =
      this.canUseAlphanumericSMSSender;

    if (
      this.canUseAlphanumericSMSSender &&
      this.adminConfiguration &&
      this.adminConfiguration.alphanumeric_sms_sender
    ) {
      this.settings.alphanumericSmsSender =
        this.adminConfiguration.alphanumeric_sms_sender;
    } else {
      this.settings.alphanumericSmsSender = "Calendesk";
    }
  },
  methods: {
    ...mapActions({
      updateConfiguration: "setup/updateConfiguration",
      fetchConfiguration: "setup/fetchConfiguration",
    }),
    handleSave() {
      if (this.$refs.form.validate()) {
        this.updateData();
      } else {
        errorNotification("form_is_invalid", {}, false);
      }
    },
    updateData() {
      this.isLoading = true;

      pushEvent("updateCompanyContactData");

      this.updateConfiguration(this.convertSettings())
        .then(() => {
          successNotification("company_data_saved");
        })
        .catch((error) => {
          errorNotification("company_data_failed", error);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    convertSettings() {
      const data = this.$helpers.copyObj(this.settings);

      const result = {
        company_name: data.companyName,
        company_country_iso_code: data.companyCountryIsoCode,
        company_street: data.companyStreet,
        company_city: data.companyCity,
        company_postal_code: data.companyPostalCode,
        company_phone: data.companyPhone,
        company_email: data.companyEmail,
        facebook: data.facebook,
        twitter: data.twitter,
        instagram: data.instagram,
        youtube: data.youtube,
      };

      if (this.canUpdateAlphanumericSenderSMSSetting) {
        result.alphanumeric_sms_sender = data.alphanumericSmsSender || null;
      }

      return result;
    },
  },
};
</script>
