<template>
  <v-card outlined class="stats__v-card" @click="clickHandler">
    <v-list-item>
      <v-list-item-title class="light--text">
        {{ title }}
      </v-list-item-title>
    </v-list-item>
    <v-list-item>
      <v-list-item-avatar
        v-if="!hideIcon"
        tile
        size="56"
        min-height="56"
        :color="warning ? 'red-light' : avatarBackgroundColor"
        class="stats__v-card--avatar"
      >
        <v-icon size="24" :color="warning ? 'red' : avatarIconColor">
          {{ warning ? "$alert" : icon }}
        </v-icon>
      </v-list-item-avatar>
      <v-list-item-content>
        <div
          class="d-flex align-center justify-center"
          style="min-height: 100px"
        >
          <div>
            <div
              v-if="!hideNumber"
              :class="{
                'error--text': warning,
                'text-h4': true,
              }"
            >
              {{ number }}
            </div>
            <small>
              {{ subtitle }}
            </small>
          </div>
        </div>
      </v-list-item-content>
    </v-list-item>
    <v-divider class="mx-2" />
    <v-card-actions v-if="buttonTitle">
      <v-btn text block color="blue" @click.stop="clickHandler">
        {{ buttonTitle }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>
<script>
export default {
  name: "DashboardStatsCard",
  props: {
    avatarBackgroundColor: {
      type: String,
      default: "pink",
    },
    avatarIconColor: {
      type: String,
      default: "primary",
    },
    icon: {
      type: String,
      default: "$account-multiple",
    },
    title: {
      type: String,
      required: true,
    },
    number: {
      type: [Number, String],
      required: true,
    },
    subtitle: {
      type: [Number, String],
      required: false,
      default: null,
    },
    redirect: {
      type: String,
      default: null,
    },
    redirectParams: {
      type: Object,
      default: () => {},
    },
    redirectQuery: {
      type: Object,
      default: () => {},
    },
    warning: {
      type: Boolean,
      default: false,
    },
    hideNumber: {
      type: Boolean,
      default: false,
    },
    hideIcon: {
      type: Boolean,
      default: false,
    },
    buttonTitle: {
      type: String,
      default: null,
    },
    buttonHandler: {
      type: Function,
      default: null,
    },
    buttonData: {
      type: Object,
      default: null,
    },
  },
  created() {},
  methods: {
    clickHandler() {
      if (this.buttonHandler) {
        this.buttonHandler(this.buttonData);
      } else if (this.redirect) {
        // Preprocess query to handle arrays as comma-separated strings
        const processedQuery = Object.fromEntries(
          Object.entries(this.redirectQuery || {}).map(([key, value]) => [
            key,
            Array.isArray(value) ? value.join(",") : value,
          ]),
        );

        this.$router.push({
          name: this.redirect,
          params: { ...this.redirectParams },
          query: { ...processedQuery },
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.stats__v-card {
  border-radius: 8px !important;
}
.stats__v-card--avatar {
  border-radius: 6px !important;
}
</style>
