import { handleWdayRule, handleDateRule } from "@/calendesk/tools/ruleHandlers";
import { adminConfig } from "@/lib/calendesk-js-library/prototypes/adminConfig";
import store from "@/store";
import { getItem } from "@/lib/calendesk-js-library/tools/localStorageManager";
import moment from "@/lib/calendesk-js-library/plugins/moment";
import helpers from "@/lib/calendesk-js-library/tools/helpers";

export function getResourceFreeSlots(info, successCallback) {
  const debugData = [];
  const viewStart = moment(info.startStr, helpers.isoFormat)
    .tz(info.timeZone)
    .subtract("1", "days");
  const viewEnd = moment(info.endStr, helpers.isoFormat)
    .tz(info.timeZone)
    .add("1", "days");

  // debugData.push({
  //   step: `Calendar range: ${viewStart.format(helpers.isoFormat)} - ${viewEnd.format(helpers.isoFormat)} in ${info.timeZone}`,
  //   value: null,
  // });

  // Calculate the difference in days
  const daysDifference = viewEnd.diff(viewStart, "days");

  if (daysDifference > 20) {
    // HACK: Skipping getResourceFreeSlots as the range exceeds 20 days (can be anything from 7 + 3 + 3 (timezone offset)).
    // We don't show this in the monthly view.
    // This logic should be in the parent.
    successCallback([]);
    // console.log("DEBUG DATA:", debugData);
    return;
  }

  const employeeWorkingHours = adminConfig(
    "app_calendar_employee_working_hours_visible",
  );

  if (!employeeWorkingHours) {
    successCallback([]);
    return;
  }

  const schedules = store.getters["availabilitySchedule/schedules"] || [];
  const selectedEmployees =
    getItem("calendarSelectedEmployees", true, true) || [];

  if (!selectedEmployees.length) {
    successCallback([]);
    return;
  }

  const events = [];
  const processedDatesPerEmployee = {}; // Tracks dates processed per employee

  schedules.forEach((schedule) => {
    if (!schedule.employees) return;

    schedule.employees.forEach((employee) => {
      if (!selectedEmployees.includes(employee.id)) return;

      if (!processedDatesPerEmployee[employee.id]) {
        processedDatesPerEmployee[employee.id] = new Set();
      }

      (schedule.rules || []).forEach((rule) => {
        if (rule.type === helpers.scheduleRuleType.DATE) {
          // Process date rules within the view range
          const targetDate = moment.tz(
            rule.date,
            "YYYY-MM-DD",
            schedule.timezone,
          );

          if (targetDate.isBetween(viewStart, viewEnd, "day", "[]")) {
            const result = handleDateRule(
              schedule,
              employee,
              rule,
              processedDatesPerEmployee,
              info,
            );
            events.push(...result.events);
            debugData.push(...result.debugData);
          } else {
            // debugData.push({
            //   step: `Date found in rules is NOT Between viewStart&viewEnd - SKIPPED: ${rule.date} in ${schedule.timezone}`,
            //   value: targetDate.format("YYYY-MM-DD"),
            // });
          }
        }
      });

      (schedule.rules || []).forEach((rule) => {
        if (rule.type === helpers.scheduleRuleType.WDAY) {
          const result = handleWdayRule(
            schedule,
            employee,
            rule,
            processedDatesPerEmployee,
            info,
          );
          events.push(...result.events);
          debugData.push(...result.debugData);
        }
      });

      // console.log("processedDatesPerEmployee", processedDatesPerEmployee);
    });
  });

  // console.log("DEBUG DATA:", debugData, events);
  successCallback(events, debugData);
}
