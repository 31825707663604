<template>
  <v-menu
    ref="datepickerMenu"
    v-model="datepickerMenu"
    :close-on-content-click="false"
    transition="scale-transition"
    offset-y
    max-width="290px"
    min-width="290px"
  >
    <template #activator="{ on }">
      <v-btn text min-width="290px" v-on="on">
        {{
          date
            | moment(
              $vuetify.breakpoint.mdAndUp
                ? "dddd, D MMMM YYYY"
                : "ddd, D MMM YYYY",
            )
        }}
      </v-btn>
    </template>
    <v-date-picker
      v-model="date"
      first-day-of-week="1"
      no-title
      @input="$emit('input', date)"
    />
  </v-menu>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "CalendarToolbarDatepicker",
  model: {
    prop: "value",
    event: "input",
  },
  props: {
    value: {
      type: String,
      default: "",
    },
    label: {
      type: String,
      default: "",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      datepickerMenu: false,
      date: this.value,
    };
  },
  computed: {
    ...mapGetters({
      selectedDate: "calendar/getSelectedDate",
    }),
  },
  watch: {
    selectedDate() {
      this.date = this.selectedDate;
    },
    date() {
      this.datepickerMenu = false;
    },
  },
};
</script>
