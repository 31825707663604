import { mapActions, mapGetters } from "vuex";
import helpers, { pushEvent } from "@/lib/calendesk-js-library/tools/helpers";
import dialogTypes from "@/components/Dialogs/dialogTypes";
import dialogSize from "@/lib/calendesk-js-library/components/dialogs/dialogSize";
import sharedActions from "@/lib/calendesk-js-library/mixins/sharedActions";

export default {
  mixins: [sharedActions],
  data() {
    return {
      selectedPlanSlug: null,
      selectedPeriodType: null,
      statsMode: null,
      isFetchingPlans: false,
      allowedPackages: [
        "sms-notifications",
        "alphanumeric-sms-sender",
        "storage_100gb",
        "storage_500gb",
      ],
    };
  },
  computed: {
    ...mapGetters({
      plans: "packages/getPlans",
      currentPlan: "packages/getCurrentTenantPlan",
      defaultPaymentMethod: "payments/getDefaultPaymentMethod",
      invoiceData: "payments/getInvoiceData",
      subscriptionToPay: "payments/getSubscriptionToPay",
      adminConfiguration: "setup/getAdminConfiguration",
      stats: "dashboard/getStats",
      getHasAnyPastDueInvoice: "setup/getHasAnyPastDueInvoice",
      stripeAccount: "stripe/getAccount",
      apiKey: "integrations/getApiKey",
      googleRecentlyDisabledByUser: "google/getRecentlyDisabledByUser",
      outlookRecentlyDisabledByUser: "microsoft/getRecentlyDisabledByUser",
      whatsappRecentlyDisabledByUser:
        "employee/getWhatsappRecentlyDisabledByUser",
    }),
    isGoogleCalendarEnabled() {
      return (
        !this.googleRecentlyDisabledByUser &&
        this.loggedUser &&
        this.loggedUser.employee &&
        this.loggedUser.employee.google_calendar_enabled
      );
    },
    isOutlookCalendarEnabled() {
      return (
        !this.outlookRecentlyDisabledByUser &&
        this.loggedUser &&
        this.loggedUser.employee &&
        this.loggedUser.employee.outlook_enabled
      );
    },
    isZoomEnabled() {
      return (
        this.loggedUser &&
        this.loggedUser.employee &&
        this.loggedUser.employee.zoom_enabled
      );
    },
    isWhatsAppEnabled() {
      return (
        !this.whatsappRecentlyDisabledByUser &&
        this.loggedUser &&
        this.loggedUser.employee &&
        this.loggedUser.employee.whatsapp_number
      );
    },
    isStripeEnabled() {
      return !!this.stripeAccount;
    },
    isFileStorageEnabled() {
      return this.currentPlan.slug === this.$helpers.plans.CD_BASE_PRO;
    },
    isFakturowniaEnabled() {
      return (
        this.fakturowniaPackage &&
        this.fakturowniaPackage.config &&
        this.fakturowniaPackage.config.is_enabled
      );
    },
    fakturowniaPackage() {
      if (this.currentPlan && this.currentPlan.tenant_packages) {
        const result = this.currentPlan.tenant_packages.filter(
          (obj) => obj.package.service.slug === "fakturownia",
        );

        if (result.length > 0) {
          return result[0];
        }
      }

      return null;
    },
    isApiEnabled() {
      return !!this.apiKey;
    },
    isStripeActive() {
      return this.stripeAccount && this.stripeAccount.charges_enabled;
    },
    numberOfEmployees() {
      if (this.stats) {
        return this.stats.employees;
      }

      return null;
    },
    numberOfWebDrafts() {
      if (this.stats) {
        return this.stats.web_drafts;
      }

      return null;
    },
    isCancelingStripeSubscription() {
      return this.planExpiryDate && this.hasActiveStripeSubscription;
    },
    hasActiveStripeSubscription() {
      return (
        this.currentPlan &&
        this.currentPlan.subscription &&
        (this.currentPlan.subscription.stripe_status === "trailing" ||
          this.currentPlan.subscription.stripe_status === "active")
      );
    },
    planExpiryDate() {
      if (this.hasActiveStripeSubscription) {
        if (this.currentPlan.subscription.ends_at) {
          return this.$moment(this.currentPlan.subscription.ends_at).format(
            helpers.dayMonthAndYearDate,
          );
        }

        return null;
      }

      if (this.currentPlan && this.currentPlan.current_period_end) {
        return this.$moment(this.currentPlan.current_period_end).format(
          helpers.dayMonthAndYearDate,
        );
      }

      return null;
    },
    currentPeriodEndsAt() {
      if (this.currentPlan && this.currentPlan.current_period_end) {
        return this.$moment(this.currentPlan.current_period_end).format(
          helpers.dayMonthAndYearDate,
        );
      }

      return null;
    },
    subscriptionInfo() {
      if (this.currentPlan) {
        if (this.currentPlan.period === this.$helpers.periodTypes.MONTH) {
          return this.$trans("monthly_subscription").toLowerCase();
        }
        if (this.currentPlan.period === this.$helpers.periodTypes.YEAR) {
          return this.$trans("yearly_subscription").toLowerCase();
        }
      }

      return null;
    },
    onTrial() {
      return (
        this.currentPlan &&
        this.currentPlan.on_trial &&
        !this.currentPlan.subscription
      );
    },
    plan() {
      if (this.plans) {
        if (this.selectedPlanSlug) {
          return (
            this.plans.find((plan) => plan.slug === this.selectedPlanSlug) ||
            null
          );
        }
        if (this.currentPlan) {
          return (
            this.plans.find((plan) => plan.slug === this.currentPlan.slug) ||
            null
          );
        }
      }

      return null;
    },
    period() {
      if (this.plan) {
        return this.plan.periods.find(
          (period) => period.period === this.periodType,
        );
      }

      return null;
    },
    periodType() {
      if (this.selectedPeriodType) {
        return this.selectedPeriodType;
      }

      return this.currentPlan.period;
    },
    periodId() {
      return this.period ? this.period.id : null;
    },
    planPackages() {
      if (this.plan) {
        return this.plan.packages.filter(
          (item) => item.type === this.$helpers.planPackagesTypes.PLAN,
        );
      }

      return null;
    },
    currentPeriodPlanPackages() {
      const result = [];
      if (this.plan) {
        if (this.periodType === this.$helpers.periodTypes.MONTH) {
          result.push(...this.monthlyPlanPackages);
        } else {
          result.push(...this.yearlyPlanPackages);
        }

        result.push(...this.extraPackagesOneTime);
      }

      return result;
    },
    monthlyPlanPackages() {
      if (this.plan && this.plan.packages.length > 0) {
        return this.plan.packages.filter(
          (item) =>
            item.type === this.$helpers.planPackagesTypes.PLAN &&
            item.period === this.$helpers.periodTypes.MONTH &&
            this.allowedPackages.includes(item.service.slug),
        );
      }

      return [];
    },
    yearlyPlanPackages() {
      if (this.plan && this.plan.packages.length > 0) {
        return this.plan.packages.filter(
          (item) =>
            item.type === this.$helpers.planPackagesTypes.PLAN &&
            item.period === this.$helpers.periodTypes.YEAR &&
            this.allowedPackages.includes(item.service.slug),
        );
      }

      return [];
    },
    extraPackages() {
      if (this.plan && this.plan.packages.length > 0) {
        return this.plan.packages.filter(
          (item) =>
            item.type === this.$helpers.planPackagesTypes.EXTRA &&
            this.allowedPackages.includes(item.service.slug),
        );
      }

      return [];
    },
    extraPackagesOneTime() {
      if (this.plan && this.plan.packages.length > 0) {
        return this.plan.packages.filter(
          (item) =>
            item.type === this.$helpers.planPackagesTypes.EXTRA &&
            !item.unit_priced &&
            this.allowedPackages.includes(item.service.slug),
        );
      }

      return [];
    },
    extraPackagesUnitPriced() {
      if (this.plan && this.plan.packages.length > 0) {
        return this.plan.packages.filter(
          (item) =>
            item.type === this.$helpers.planPackagesTypes.EXTRA &&
            item.unit_priced,
        );
      }

      return [];
    },
    maxNumberOfEmployees() {
      switch (this.currentPlan.slug) {
        case this.$helpers.plans.CD_BASE_LITE:
          return this.$helpers.maxNumbersInPlan.lite.employees;
        case this.$helpers.plans.CD_BASE_STANDARD:
          return this.$helpers.maxNumbersInPlan.standard.employees;
        default:
          return Number.MAX_SAFE_INTEGER;
      }
    },
    canUseServiceVariants() {
      switch (this.currentPlan.slug) {
        case this.$helpers.plans.CD_BASE_LITE:
          return this.hasPackageInCurrentPlan("booking");
        default:
          return true;
      }
    },
    canUseAlphanumericSMSSender() {
      return this.hasPackageInCurrentPlan("alphanumeric-sms-sender");
    },
    canUseFakturownia() {
      switch (this.currentPlan.slug) {
        case this.$helpers.plans.CD_BASE_LITE:
          return false;
        default:
          return true;
      }
    },
    canUseCustomBookingReminders() {
      switch (this.currentPlan.slug) {
        case this.$helpers.plans.CD_BASE_LITE:
        case this.$helpers.plans.CD_BASE_STANDARD:
          return false;
        default:
          return true;
      }
    },
    canUseStripe() {
      switch (this.currentPlan.slug) {
        case this.$helpers.plans.CD_BASE_LITE:
          return false;
        default:
          return true;
      }
    },
    canUseGtm() {
      switch (this.currentPlan.slug) {
        case this.$helpers.plans.CD_BASE_LITE:
          return false;
        default:
          return true;
      }
    },
    canAddWebsite() {
      return this.numberOfWebDrafts < this.maxNumberOfWebDrafts;
    },
    canUseServiceLocations() {
      switch (this.currentPlan.slug) {
        case this.$helpers.plans.CD_BASE_LITE:
          return false;
        default:
          return true;
      }
    },
    canAddEmployees() {
      return this.numberOfEmployees < this.maxNumberOfEmployees;
    },
    canUseApi() {
      return this.hasPackageInCurrentPlan("api");
    },
    canUseZoom() {
      return this.hasPackageInCurrentPlan("zoom");
    },
    canUseGoogleCalendar() {
      return this.hasPackageInCurrentPlan("google-calendar");
    },
    canUseWhatsApp() {
      return this.hasPackageInCurrentPlan("whatsapp");
    },
    canUseOutlookCalendar() {
      return this.hasPackageInCurrentPlan("outlook");
    },
    canGenerateRegulations() {
      switch (this.currentPlan.slug) {
        case this.$helpers.plans.CD_BASE_LITE:
          return false;
        default:
          return true;
      }
    },
    canCreateMobileAppForCustomers() {
      switch (this.currentPlan.slug) {
        case this.$helpers.plans.CD_BASE_LITE:
          return false;
        default:
          return true;
      }
    },
    canUseNewsletter() {
      switch (this.currentPlan.slug) {
        case this.$helpers.plans.CD_BASE_LITE:
          return false;
        default:
          return true;
      }
    },
    canSendCustomerNotifications() {
      switch (this.currentPlan.slug) {
        case this.$helpers.plans.CD_BASE_LITE:
          return false;
        default:
          return true;
      }
    },
    canUseSimpleStoreProduct() {
      switch (this.currentPlan.slug) {
        case this.$helpers.plans.CD_BASE_LITE:
          return false;
        default:
          return true;
      }
    },
    canUseSubscriptions() {
      switch (this.currentPlan.slug) {
        case this.$helpers.plans.CD_BASE_LITE:
          return false;
        default:
          return true;
      }
    },
    maxNumberOfWebDrafts() {
      switch (this.currentPlan.slug) {
        case this.$helpers.plans.CD_BASE_LITE:
          return this.$helpers.maxNumbersInPlan.lite.webDrafts;
        case this.$helpers.plans.CD_BASE_STANDARD:
          return this.$helpers.maxNumbersInPlan.standard.webDrafts;
        default:
          return Number.MAX_SAFE_INTEGER;
      }
    },
    price() {
      return this.period ? this.period.price.amount : 0;
    },
    tenantPlanPackages() {
      if (this.currentPlan && this.currentPlan.tenant_packages) {
        return this.currentPlan.tenant_packages.filter(
          (tenantPackage) =>
            tenantPackage.package.type === this.$helpers.planPackagesTypes.PLAN,
        );
      }

      return [];
    },
    tenantConfigPackages() {
      if (this.currentPlan && this.currentPlan.tenant_packages) {
        return this.currentPlan.tenant_packages.filter(
          (tenantPackage) =>
            tenantPackage.package.type ===
            this.$helpers.planPackagesTypes.CONFIG,
        );
      }

      return [];
    },
    tenantExtraPackages() {
      if (this.currentPlan && this.currentPlan.tenant_packages) {
        return this.currentPlan.tenant_packages.filter(
          (tenantPackage) =>
            tenantPackage.package.type ===
            this.$helpers.planPackagesTypes.EXTRA,
        );
      }

      return [];
    },
  },
  methods: {
    ...mapActions({
      fetchUsage: "notification/fetchUsage",
      packagesPurchase: "packages/packagesPurchase",
      setSubscriptionToPay: "payments/setSubscriptionToPay",
      fetchCurrentTenantPlan: "packages/fetchCurrentTenantPlan",
      subscribePlan: "payments/subscribePlan",
      fetchRequiredAppComponents: "setup/fetchRequiredAppComponents",
    }),
    openPastDueDialog() {
      this.$root.$emit("openPastDueView", false);
    },
    hasPackageInCurrentPlan(slug) {
      if (this.currentPlan && this.currentPlan.tenant_packages) {
        return !!this.currentPlan.tenant_packages.find(
          (tenantPackage) =>
            tenantPackage.package &&
            tenantPackage.package.service &&
            tenantPackage.package.service.slug === slug,
        );
      }

      return false;
    },
    goToPlan() {
      this.$root.$emit("openPlanView", false);
    },
    openFeatureNotAvailableDialog() {
      this.$root.$emit("openFeatureNotAvailableDialog");
    },
    openPackageNotAvailableDialog() {
      this.$root.$emit("openPackageNotAvailableDialog");
    },
    cancelPlan() {
      pushEvent("openCancelPlanDialog");

      const large =
        this.currentPlan &&
        (this.currentPlan.slug === this.$helpers.plans.CD_BASE_PRO ||
          this.currentPlan.slug === this.$helpers.plans.CD_BASE_STANDARD);

      this.openDialog({
        type: dialogTypes.CANCEL_PLAN_DIALOG,
        size: large ? dialogSize.LARGE : dialogSize.SMALL,
        title: this.$trans("cancel_plan_2"),
      });
    },
  },
};
