<template>
  <tr
    :key="item.id"
    :class="{
      'v-data-table__mobile-table-row': !$vuetify.breakpoint.mdAndUp,
    }"
    class="table-row"
    @mouseleave="isHovered = false"
    @mouseover="isHovered = true"
    @click.stop="clickRow(item)"
  >
    <td
      :class="{
        'v-data-table__mobile-row': !$vuetify.breakpoint.mdAndUp,
        'v-data-table__checkbox-row': $vuetify.breakpoint.mdAndUp,
      }"
      class="pt-2 text-center"
    >
      <div
        v-if="!$vuetify.breakpoint.mdAndUp"
        class="v-data-table__mobile-row__header"
      ></div>
      <div
        :class="{
          'v-data-table__mobile-row__cell': !$vuetify.breakpoint.mdAndUp,
        }"
      >
        <v-simple-checkbox
          :ripple="false"
          :value="isSelected"
          color="primary"
          @input="selectOnChange($event)"
        />
      </div>
    </td>
    <td
      :class="{
        'v-data-table__mobile-row': !$vuetify.breakpoint.mdAndUp,
        'v-data-table__5-row': $vuetify.breakpoint.mdAndUp,
      }"
      class="pt-2 text-center"
    >
      <div
        v-if="!$vuetify.breakpoint.mdAndUp"
        class="v-data-table__mobile-row__header"
      >
        <div>
          {{ $trans("id") }}
        </div>
      </div>
      <div
        :class="{
          'v-data-table__mobile-row__cell': !$vuetify.breakpoint.mdAndUp,
        }"
      >
        <div class="text-left">
          <div>{{ item.id }}</div>
        </div>
      </div>
    </td>
    <td
      :class="{
        'v-data-table__mobile-row': !$vuetify.breakpoint.mdAndUp,
        'v-data-table__25-row': $vuetify.breakpoint.mdAndUp,
      }"
      class="py-2"
    >
      <div
        v-if="!$vuetify.breakpoint.mdAndUp"
        class="v-data-table__mobile-row__header"
      >
        {{ $trans("client_names") }}
      </div>
      <div
        :class="{
          'v-data-table__mobile-row__cell': !$vuetify.breakpoint.mdAndUp,
        }"
      >
        <div class="employee-details">
          <div class="mb-2">
            <user-card-details
              :user="item"
              clickable
              details-in-tooltip
              justify-left
              push-transition
            />
          </div>
          <div class="d-flex align-center flex-wrap">
            <v-chip
              :class="$helpers.getStatusColor(item.status).class"
              :color="$helpers.getStatusColor(item.status).color"
              class="mr-2"
              x-small
            >
              {{ $trans(item.status + "_account") }}
            </v-chip>
          </div>
          <div
            v-if="item.groups && item.groups.length > 0"
            class="d-flex align-center flex-wrap mt-2"
          >
            <v-chip
              v-for="group in item.groups"
              :key="group.id"
              class="my-1 mr-2"
              x-small
            >
              <div>
                <span class="font-weight-medium">{{ group.name }}</span>
              </div>
            </v-chip>
          </div>
        </div>
      </div>
    </td>
    <td
      :class="{
        'v-data-table__mobile-row': !$vuetify.breakpoint.mdAndUp,
        'v-data-table__15-row': $vuetify.breakpoint.mdAndUp,
      }"
      class="py-2"
    >
      <div
        v-if="!$vuetify.breakpoint.mdAndUp"
        class="v-data-table__mobile-row__header"
      >
        {{ $trans("email") }}
      </div>
      <div
        :class="{
          'v-data-table__mobile-row__cell': !$vuetify.breakpoint.mdAndUp,
        }"
      >
        <div
          v-if="item.email"
          class="pointer"
          @click.stop="$helpers.copyAndNotify(item.email)"
        >
          {{ item.email }}
        </div>
      </div>
    </td>
    <td
      :class="{
        'v-data-table__mobile-row': !$vuetify.breakpoint.mdAndUp,
        'v-data-table__15-row': $vuetify.breakpoint.mdAndUp,
      }"
      class="py-2"
    >
      <div
        v-if="!$vuetify.breakpoint.mdAndUp"
        class="v-data-table__mobile-row__header"
      >
        {{ $trans("phone") }}
      </div>
      <div
        :class="{
          'v-data-table__mobile-row__cell': !$vuetify.breakpoint.mdAndUp,
        }"
      >
        <div
          v-if="item.default_phone"
          class="pointer"
          @click.stop="$helpers.copyAndNotify(item.default_phone.e164)"
        >
          {{ item.default_phone.e164 }}
        </div>
      </div>
    </td>
    <td
      :class="{
        'v-data-table__mobile-row': !$vuetify.breakpoint.mdAndUp,
        'v-data-table__15-row': $vuetify.breakpoint.mdAndUp,
      }"
      class="py-2"
    >
      <div
        v-if="!$vuetify.breakpoint.mdAndUp"
        class="v-data-table__mobile-row__header"
      >
        {{ $trans("created_at") }}
      </div>
      <div
        :class="{
          'v-data-table__mobile-row__cell': !$vuetify.breakpoint.mdAndUp,
        }"
      >
        {{ item.created_at | dateTime($helpers.hourDayMonthAndYearDate) }}
      </div>
    </td>
    <td
      v-if="$config('customer_mobile_app_enabled')"
      :class="{
        'v-data-table__mobile-row': !$vuetify.breakpoint.mdAndUp,
        'v-data-table__30-row': $vuetify.breakpoint.mdAndUp,
      }"
      class="py-2"
    >
      <div
        v-if="!$vuetify.breakpoint.mdAndUp"
        class="v-data-table__mobile-row__header"
      >
        {{ $trans("integrations") }}
      </div>
      <div
        :class="{
          'v-data-table__mobile-row__cell': !$vuetify.breakpoint.mdAndUp,
        }"
      >
        <div class="d-flex align-center justify-center flex-wrap">
          <v-tooltip v-if="item.accepts_push" bottom>
            <template #activator="{ on }">
              <v-icon color="green" v-on="on"> $smartphone</v-icon>
            </template>
            {{ $trans("push_tooltip") }}
          </v-tooltip>
        </div>
      </div>
    </td>
    <td
      :class="{
        'v-data-table__mobile-row': !$vuetify.breakpoint.mdAndUp,
        'v-data-table__manage-row': $vuetify.breakpoint.mdAndUp,
      }"
      class="py-2"
    >
      <div class="row-list-buttons white rounded mr-1 mt-2">
        <user-list-buttons :is-hovered="isHovered" :user="item" />
      </div>
    </td>
  </tr>
</template>
<script>
import UserCardDetails from "@/components/UserCardDetails.vue";
import UserListButtons from "@/views/dashboard/pages/Users/components/UserListButtons.vue";
import itemRowActions from "@/calendesk/mixins/itemRowActions";

export default {
  components: {
    UserListButtons,
    UserCardDetails,
  },
  mixins: [itemRowActions],
};
</script>
