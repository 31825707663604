<template>
  <v-container v-if="hasStats" fluid class="px-0 mx-0">
    <v-row class="mb-2">
      <v-col v-if="notificationUsage" cols="12" sm="6" md="3">
        <dashboard-stats-card
          :warning="!onTrial && notificationUsage.email <= 50"
          :number="+notificationUsage.email"
          :title="$trans('email_notifications')"
          icon="$email"
          avatar-background-color="green-light"
          avatar-icon-color="green"
          :button-title="$trans('buy_more')"
          :button-handler="buyNotificationButtonClicked"
          :button-data="{ slug: 'email-notifications' }"
        />
      </v-col>
      <v-col v-if="notificationUsage" cols="12" sm="6" md="3">
        <dashboard-stats-card
          :warning="!onTrial && notificationUsage.sms <= 10"
          :number="+notificationUsage.sms"
          :title="$trans('sms_notifications')"
          icon="$mobile"
          avatar-background-color="green-light"
          avatar-icon-color="green"
          :button-title="$trans('buy_more')"
          :button-handler="buyNotificationButtonClicked"
          :button-data="{ slug: 'sms-notifications' }"
        />
      </v-col>
      <v-col cols="12" sm="6" md="3">
        <dashboard-stats-card
          :hide-number="!isFileStorageEnabled"
          :hide-icon="!isFileStorageEnabled"
          :warning="checkStorageWarning"
          :number="getCurrentStorageUsageInfo"
          :subtitle="getCurrentStorageAvailabilityInfo"
          :title="$trans('files_storage_usage')"
          icon="$database"
          avatar-background-color="green-light"
          avatar-icon-color="green"
          :button-title="
            isFileStorageEnabled
              ? $trans('buy_more')
              : $trans('change_plan_button_title')
          "
          redirect="billing"
          :redirect-params="{ tab: 'subscription' }"
          :button-handler="isFileStorageEnabled ? null : goToPlan"
        />
      </v-col>
      <v-col cols="12" sm="6" md="3">
        <dashboard-stats-card
          redirect="users"
          :number="+stats.users"
          :title="$trans('users')"
          icon="$account-multiple"
          avatar-background-color="pink"
          avatar-icon-color="primary"
          :button-title="$trans('go_to_tab')"
        />
      </v-col>
      <v-col v-if="getDomain" cols="12" md="6">
        <v-card outlined class="stats__v-card">
          <v-list-item>
            <v-list-item-title class="light--text">
              {{ $trans("websites") }} /
              {{ $trans("integrations_category_calendars") }}
            </v-list-item-title>
          </v-list-item>
          <v-list-item>
            <v-list-item-avatar
              tile
              size="56"
              min-height="56"
              color="yellow-light"
              class="stats__v-card--avatar"
            >
              <v-icon size="24" color="orange"> $application </v-icon>
            </v-list-item-avatar>
            <v-list-item-content>
              <div
                style="
                  min-height: 100px;
                  display: flex;
                  flex-direction: column;
                  justify-content: center;
                  align-items: flex-start;
                "
              >
                <div
                  v-for="webDraft in webDrafts.slice(0, 3)"
                  :key="webDraft.domain.domain"
                  class="mb-1"
                  style="text-align: left"
                >
                  <a
                    style="display: inline-block"
                    @click.stop="
                      $helpers.openUrl('https://' + webDraft.domain.domain)
                    "
                  >
                    {{ `https://${webDraft.domain.domain}` | truncate(55) }}
                  </a>
                </div>
              </div>
            </v-list-item-content>
          </v-list-item>
          <v-divider class="mx-2" />
          <v-card-actions>
            <v-btn text block color="blue" @click="goToWebsites">
              {{ $trans("go_to_tab") }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
      <v-col cols="12" sm="6" md="3">
        <dashboard-stats-card
          redirect="sales"
          :redirect-params="{ tab: 'subscriptions' }"
          :number="+stats.active_user_subscriptions"
          :title="$trans('number_of_active_user_subscriptions')"
          icon="$subscriptions"
          avatar-background-color="pink"
          avatar-icon-color="primary"
          :button-title="$trans('go_to_tab')"
        />
      </v-col>
      <v-col cols="12" sm="6" md="3">
        <dashboard-stats-card
          redirect="notifications"
          :redirect-params="{ tab: 'newsletter' }"
          :number="+stats.newsletter"
          :title="$trans('number_of_newsletter_members')"
          icon="$email"
          avatar-background-color="pink"
          avatar-icon-color="primary"
          :button-title="$trans('go_to_tab')"
        />
      </v-col>

      <v-col cols="12" sm="6" md="3">
        <dashboard-stats-card
          redirect="sales"
          :redirect-params="{ tab: 'events' }"
          :redirect-query="{
            status: [bookingStatuses.approved, bookingStatuses.done],
          }"
          :number="+stats.bookings_all_processed"
          :title="$trans('bookings_all_processed')"
          icon="$calendar-range"
          avatar-background-color="blue-light"
          avatar-icon-color="blue"
          :button-title="$trans('go_to_tab')"
        />
      </v-col>
      <v-col cols="12" sm="6" md="3">
        <dashboard-stats-card
          redirect="sales"
          :redirect-params="{ tab: 'events' }"
          :redirect-query="{
            period: 'today',
            status: [bookingStatuses.approved, bookingStatuses.done],
          }"
          :number="+stats.bookings_today"
          :title="$trans('bookings_today')"
          icon="$calendar"
          avatar-background-color="blue-light"
          avatar-icon-color="blue"
          :button-title="$trans('go_to_tab')"
        />
      </v-col>

      <v-col cols="12" sm="6" md="3">
        <dashboard-stats-card
          redirect="sales"
          :redirect-params="{ tab: 'events' }"
          :redirect-query="{
            period: 'week',
            status: [bookingStatuses.approved, bookingStatuses.done],
          }"
          :number="+stats.bookings_this_week"
          :title="$trans('bookings_this_week')"
          icon="$calendar-range"
          avatar-background-color="blue-light"
          avatar-icon-color="blue"
          :button-title="$trans('go_to_tab')"
        />
      </v-col>
      <v-col cols="12" sm="6" md="3">
        <dashboard-stats-card
          redirect="sales"
          :redirect-params="{ tab: 'events' }"
          :redirect-query="{
            period: 'month',
            status: [bookingStatuses.approved, bookingStatuses.done],
          }"
          :number="+stats.bookings_this_month"
          :title="$trans('bookings_this_month')"
          icon="$calendar-range"
          avatar-background-color="blue-light"
          avatar-icon-color="blue"
          :button-title="$trans('go_to_tab')"
        />
      </v-col>

      <v-col cols="12" sm="6" md="3">
        <dashboard-stats-card
          redirect="sales"
          :redirect-params="{ tab: 'products' }"
          :number="+stats.simple_store_product_transactions"
          :title="$trans('number_of_simple_store_product_transactions')"
          icon="$cart"
          avatar-background-color="blue-light"
          avatar-icon-color="blue"
          :button-title="$trans('go_to_tab')"
        />
      </v-col>
      <v-col cols="12" sm="6" md="3">
        <dashboard-stats-card
          redirect="sales"
          :redirect-params="{ tab: 'products' }"
          :redirect-query="{ period: 'today' }"
          :number="+stats.simple_store_product_transactions_today"
          :title="$trans('number_of_simple_store_product_transactions_today')"
          icon="$cart"
          avatar-background-color="blue-light"
          avatar-icon-color="blue"
          :button-title="$trans('go_to_tab')"
        />
      </v-col>
      <v-col cols="12" sm="6" md="3">
        <dashboard-stats-card
          redirect="sales"
          :redirect-params="{ tab: 'products' }"
          :redirect-query="{ period: 'week' }"
          :number="+stats.simple_store_product_transactions_this_week"
          :title="
            $trans('number_of_simple_store_product_transactions_this_week')
          "
          icon="$cart"
          avatar-background-color="blue-light"
          avatar-icon-color="blue"
          :button-title="$trans('go_to_tab')"
        />
      </v-col>
      <v-col cols="12" sm="6" md="3">
        <dashboard-stats-card
          redirect="sales"
          :redirect-params="{ tab: 'products' }"
          :redirect-query="{ period: 'month' }"
          :number="+stats.simple_store_product_transactions_this_month"
          :title="$trans('simple_store_product_transactions_this_month')"
          icon="$cart"
          avatar-background-color="blue-light"
          avatar-icon-color="blue"
          :button-title="$trans('go_to_tab')"
        />
      </v-col>
    </v-row>

    <dashboard-charts :stats="stats" />
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import DashboardCharts from "./DashboardCharts";
import DashboardStatsCard from "./DasboardStatsCard";
import dialogTypes from "@/components/Dialogs/dialogTypes";
import dialogSize from "@/lib/calendesk-js-library/components/dialogs/dialogSize";
import planActions from "@/calendesk/mixins/planActions";
import { EventBus } from "@/lib/calendesk-js-library/plugins/eventBus";
import { bookingStatuses } from "@/calendesk/prototypes/bookingStatuses";

export default {
  name: "DashboardStats",
  components: { DashboardStatsCard, DashboardCharts },
  mixins: [planActions],
  data() {
    return {
      bookingStatuses: bookingStatuses,
    };
  },
  computed: {
    ...mapGetters({
      storedStats: "dashboard/getStats",
      notificationUsage: "notification/getUsage",
      webDrafts: "webDraft/drafts",
    }),
    getCurrentStorageUsageInfo() {
      const usage = this.stats.files_storage_usage; // Assuming usage is in bytes

      const formatBytes = (bytes) => {
        if (bytes < 1000) {
          return `${bytes} B`;
        } else if (bytes < 1000 * 1000) {
          return `${Math.ceil(bytes / 1000)} KB`; // Round up to full KB
        } else if (bytes < 1000 * 1000 * 1000) {
          return `${Math.ceil(bytes / (1000 * 1000))} MB`; // Round up to full MB
        } else {
          return `${Math.ceil(bytes / (1000 * 1000 * 1000))} GB`; // Round up to full GB
        }
      };

      return formatBytes(usage);
    },
    getCurrentStorageAvailabilityInfo() {
      if (!this.isFileStorageEnabled) {
        return `${this.$trans("not_available_in_standard_plan")}`;
      }

      const available = this.stats.files_storage_available; // Convert to number

      const formatBytesToGB = (bytes) =>
        (bytes / (1000 * 1000 * 1000)).toFixed(0);

      return `${this.$trans("files_storage_usage_from_available_prefix")}: ${formatBytesToGB(available)} GB`;
    },
    checkStorageWarning() {
      if (!this.isFileStorageEnabled) {
        return false;
      }

      const usage = +this.stats.files_storage_usage; // Current storage usage in bytes
      const available = +this.stats.files_storage_available; // Total storage available in bytes

      const remainingStorage = available - usage; // Calculate remaining storage
      const remainingGB = remainingStorage / (1000 * 1000 * 1000); // Convert remaining storage to GB

      return remainingGB <= 1;
    },
    getDomain() {
      if (
        this.webDrafts &&
        this.webDrafts.length > 0 &&
        this.webDrafts[0].domain
      ) {
        return `https://${this.webDrafts[0].domain.domain}`;
      }

      return null;
    },
    hasStats() {
      return this.storedStats !== null;
    },
    stats() {
      return {
        ...this.storedStats,
        lastUpdated: `${this.$trans("last_update")} ${
          this.storedStats.last_updated_at
        }`,
      };
    },
  },
  methods: {
    buyNotificationButtonClicked(data) {
      if (this.loggedUserIsAdmin) {
        const isEmail = data.slug === "email-notifications";

        this.openDialog({
          type: dialogTypes.BUY_NOTIFICATION_DIALOG,
          size: dialogSize.FULL_SCREEN,
          data: this.packageObject(data.slug),
          title: isEmail
            ? this.$trans("choose_email_notifications_package")
            : this.$trans("choose_sms_notifications_package"),
        });
      } else {
        EventBus.$emit("REQUIRED_PERMISSION", data);
      }
    },
    packageObject(slug) {
      return this.extraPackagesUnitPriced.find((x) => x.service.slug === slug);
    },
    goToWebsites() {
      this.$router.push({
        name: "tools",
        params: { tab: "websites" },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.stats__v-card {
  border-radius: 8px !important;
}
.stats__v-card--avatar {
  border-radius: 6px !important;
}

.description {
  height: 100px;
}
</style>
