import store from "@/store";
import { trans } from "@/lib/calendesk-js-library/prototypes/trans";
import { errorNotification } from "@/lib/calendesk-js-library/tools/notification";
import {
  globalErrorCodes,
  pushEvent,
} from "@/lib/calendesk-js-library/tools/helpers";
import UserResponseHandler from "@/store/ResponseHandlers/UserResponseHandler";

const storedLocale =
  typeof localStorage.locale !== "undefined" ? localStorage.locale : "en";

export default async (to, from, next) => {
  if (to.name) {
    pushEvent(`goToPage_${to.name}`, {
      params: to.params,
    });
  }

  if (to && (to.name === "card3ds" || to.name === "browser")) {
    store.commit("setup/SET_PRELOADER", false);
    next();
    return;
  }

  if (to && to.name === "auth") {
    if (store.getters["auth/getCdAdminSessionIssuer"]) {
      const message =
        "Another admin session is active. Please close all sessions when finished. " +
        "Click OK to clear data from other sessions.";

      if (confirm(message)) {
        localStorage.clear();
      }
    }

    await store.dispatch("setup/setTenant", to.query.tenant || null);
    UserResponseHandler.cleanSessionInLocalStorage();

    await store
      .dispatch("auth/createAuthTokenWithSessionId", {
        session_id: to.query.session_id,
      })
      .then(() => {
        store.commit("auth/SET_IS_USER_LOGGED", true);
        next({ name: "dashboard", params: { locale: to.params.locale } });
      })
      .catch((error) => {
        errorNotification(null, error);
      });
  } else {
    store
      .dispatch("setup/init")
      .then(() => {
        const protectedRoute = to.matched.some((m) => m.meta.auth);
        const isUserLogged = store.getters["auth/isUserLogged"];

        const preferredLocale = to.params.locale
          ? to.params.locale
          : storedLocale;
        configureLocale(preferredLocale)
          .then(() => {})
          .catch((error) => {
            console.error(error);
            store
              .dispatch("setup/setupLocale", { locale: preferredLocale })
              .then(() => {
                store.dispatch(
                  "setup/setGlobalErrorCode",
                  globalErrorCodes.LOCALE_ERROR,
                );
              });
          })
          .finally(() => {
            if (protectedRoute && !isUserLogged) {
              next({ name: "login", params: { locale: storedLocale } });
            } else if (to.name === "account_activation") {
              handleAccountActivation(to, from, next);
            } else if (
              store.state.setup.tenant &&
              (!store.state.setup.configuration ||
                !store.state.setup.adminConfiguration)
            ) {
              handleGetConfiguration(next);
            } else {
              next();
            }
          });
      })
      .catch((error) => {
        errorNotification("error_occurred", error);
        next({ name: "login", params: { locale: storedLocale } });
      });
  }
};

function handleAccountActivation(to, from, next) {
  store.dispatch("setup/setTenant", to.params.tenant);
  store
    .dispatch("auth/activationAccount", to.params.token)
    .then(() => {
      store
        .dispatch("setup/fetchSettingsAndLoadUser")
        .then((userData) => {
          next({
            name: store.state.auth.firstRouteRedirectName,
            params: {
              locale:
                userData.locale ?? store.state.setup.configuration.language,
            },
          });
        })
        .catch(() => {
          localStorage.setItem(
            "accountActivationErrorMessage",
            trans("activate_account_error_message"),
          );
        })
        .finally(() => {
          store.commit("setup/SET_PRELOADER", false);
        });
    })
    .catch(() => {
      localStorage.setItem(
        "accountActivationErrorMessage",
        trans("activate_account_error_message"),
      );
      store.commit("setup/SET_PRELOADER", false);
      next({ name: "login", params: { locale: storedLocale } });
    });
}

function handleGetConfiguration(next) {
  store
    .dispatch("setup/fetchConfiguration")
    .catch((error) => {
      store.dispatch("setup/setGlobalErrorCode", globalErrorCodes.LOCALE_ERROR);
    })
    .finally(() => {
      next();
    });
}

function configureLocale(preferredLocale) {
  return new Promise((resolve, reject) => {
    const acceptedLocale = ["pl", "en", "es", "de"];
    let locale = localStorage.getItem("locale");

    if (preferredLocale && acceptedLocale.includes(preferredLocale)) {
      locale = preferredLocale;
    }

    store
      .dispatch("setup/fetchLocale")
      .then((data) => {
        if (!locale || !acceptedLocale.includes(locale)) {
          locale = data.locale;
        }

        store
          .dispatch("setup/setupLocale", {
            locale: locale,
            timezone: data.timezone,
          })
          .then(() => resolve());
      })
      .catch((error) => {
        reject(error);
      });
  });
}
