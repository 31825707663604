<template>
  <v-row :no-gutters="dense">
    <v-col
      :class="{
        'my-0 py-0 cd-p-0': dense,
      }"
      cols="12"
    >
      <div>
        <div class="font-weight-medium mb-2">
          {{ $trans("booking_form_custom_fields") }}:
        </div>
        <div>
          <div
            v-for="(field, index) in customFields"
            :key="index"
            :class="{ 'my-2': !dense }"
          >
            <div v-if="field.label" class="font-weight-medium">
              {{ field.label }}:
            </div>
            <div v-else-if="field.description" class="font-weight-medium">
              <!-- Tooltip for descriptions longer than 100 characters -->
              <v-tooltip
                v-if="
                  stripHtml(stripSurroundingPTags(field.description)).length >
                  100
                "
                bottom
                max-width="800"
              >
                <template #activator="{ on, attrs }">
                  <span v-bind="attrs" v-on="on">
                    {{
                      field.description
                        | stripHtml
                        | stripSurroundingPTags
                        | truncate(100)
                    }}:
                  </span>
                </template>
                <div
                  v-html="
                    sanitizeHtml(stripSurroundingPTags(field.description))
                  "
                />
              </v-tooltip>

              <!-- No tooltip for descriptions 100 characters or shorter -->
              <span v-else>
                {{
                  field.description
                    | stripHtml
                    | stripSurroundingPTags
                    | truncate(100)
                }}:
              </span>
            </div>

            <!-- Checkbox -->
            <template v-if="field.type === bookingDynamicFormTypes.CHECKBOX">
              <div>{{ field.value ? $trans("yes") : $trans("no") }}</div>
            </template>

            <!-- Files -->
            <template
              v-else-if="
                field.type === bookingDynamicFormTypes.FILES &&
                Array.isArray(field.value)
              "
            >
              <div>
                <div v-for="file in field.value" :key="file.uuid">
                  <span
                    v-if="
                      typeof file === 'object' && Object.keys(file).length > 0
                    "
                  >
                    <span v-for="(name, uuid) in file" :key="uuid">
                      <a :href="generateFileUrl(uuid)" target="_blank">
                        {{ name }}
                      </a>
                    </span>
                  </span>
                </div>
              </div>
            </template>

            <!-- Fallback for other types -->
            <template v-else-if="field.value">
              <div>{{ field.value }}</div>
            </template>
          </div>
        </div>
      </div>
    </v-col>
  </v-row>
</template>

<script>
import { stripSurroundingPTags } from "@/lib/calendesk-js-library/filters/stripSurroundingPTags";
import sanitizeHtml from "@/lib/calendesk-js-library/filters/sanitizeHtml";
import stripHtml from "@/lib/calendesk-js-library/filters/stripHtml";
import { mapGetters } from "vuex";

export default {
  name: "EventCustomFields",
  props: {
    customFields: {
      type: Array,
      default: () => [],
    },
    dense: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      bookingDynamicFormTypes: this.$helpers.bookingDynamicFormTypes,
    };
  },
  computed: {
    ...mapGetters({
      getTenant: "setup/getTenant",
    }),
  },
  methods: {
    stripSurroundingPTags,
    sanitizeHtml,
    stripHtml,
    generateFileUrl(uuid) {
      const appUrlBase = process.env.VUE_APP_API_URL;
      const tenantName = this.getTenant;
      return `${appUrlBase}/files/download/${uuid}?tenant=${tenantName}`;
    },
  },
};
</script>
